//Scripts
import React from 'react';
import { useTranslation } from 'react-i18next';
import { fullDayDateWithoutTime } from './../../utils/dateTimeUtils.js';
import BookingItem from './bookingItem.js';

//CSS
import './bookingGroup.css';

function BookingGroup({loginUser, logout, group, loadBookingList}) {
    const { t } = useTranslation();
    
    const showBookings = (bookings) => {
        const dayToBookings = {};
        bookings.forEach((booking) => {
            const bookingDateTime = new Date(booking["date"]);
            const dateWithoutTime = fullDayDateWithoutTime(t, bookingDateTime);
            if (dayToBookings[dateWithoutTime]) {
                dayToBookings[dateWithoutTime].push(booking);
            } else {
                dayToBookings[dateWithoutTime] = [booking];
            }
        });
    
        return Object.keys(dayToBookings).map((displayDay) => {
            const bookings = dayToBookings[displayDay];
            return (
                <fieldset key={"day-" + displayDay} className='daily-bookings'>
                    <legend className='day-hader'>{displayDay}</legend>
                    {bookings.map((booking) => {
                        return (
                                <BookingItem 
                                    key={"BookingItem-" + booking.id}
                                    booking={booking}
                                    loginUser={loginUser}
                                    logout={logout}
                                    loadBookingList={loadBookingList} >
                                </BookingItem>
                        );
                    })}
                </fieldset>
            );
        });
    };

    return (
        <div>
            {showBookings(group.bookings)}
        </div>
    );
}

export default BookingGroup;
