//Scripts
import GenericInput from './../genericInput';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { stringFormat } from './../calendar/calendarLogic';
import calendarInfo from './../calendar/calendar-info.json';
import { specificValidate } from './../utils/businessUtils.js'

//CSS
import './account.css';

function Account({loginUser, logout, updateLoginUser, minimal}) {
    //Init
    const { maxHoursCancelBeforeStart } = calendarInfo;
    const { t } = useTranslation();
    const isFetchingRef = useRef(false);
    const [userContext, setUserContext] = useState({});
    const [serverRequestStatus, setServerRequestStatus] = useState("loading");
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    var email = loginUser["email"];

    if (searchParams.get('email')) {
        email = searchParams.get('email');
    }

    //Determine whether all mandatory props are set and valid.
    let mandatoryPropsValid = false;
    if (userContext["properties"] && userContext["user"]) {
        const mandatoryProps = Object.keys(userContext.properties).filter(key => userContext.properties[key].mandatory);
        mandatoryPropsValid = mandatoryProps.every(prop => {
            if (!userContext.user[prop]) {
                return false;
            }
    
            return specificValidate(prop, userContext.properties[prop], userContext.user[prop]);
        });
    }

    const updateDataObject = (property, value) => {
        setUserContext(prevState => {
            const newUser = { ...prevState.user, [property]: value };
            return { ...prevState, user: newUser };
        });

        //exit if none of the properties which may be changed
        if (property !== "firstname" && property !== "lastname" && property !== "phone" && property !== "language_code")
            return;

        //exit if email isn't the login user
        if (email !== loginUser["email"])
            return;

        updateLoginUser(property, value);
    };

    const axiosPostCall = (property, value) => {
        // Send a POST request to updateUser.php to update the property in the database
        return axios.post('/api/account/updateUser.php', {
            email: email,
            property,
            newValue: value,
        });
    };

    const getLoginUser = () => {
        // Ensure to load only once
        if (isFetchingRef.current)
            return;
        else
            isFetchingRef.current = true;

        // Fetch user data
        const url = `/api/account/getUser.php?email=${email}`;
        axios.get(url)
        .then(response => {
            //Fill drop-down-list for language based on React app-languages
            const loadedLanguages = Object.keys(i18next.options.resources);
            const languageHash = loadedLanguages.reduce((acc, langCode) => {
                acc[langCode] = t("this-language", { lng: langCode });
                return acc;
            }, {});
            response.data.properties.language_code.list = languageHash;
            response.data.properties.language_code.type = "list";

            //Set account data
            setUserContext(response.data);

            //Set fetch status to success
            setServerRequestStatus("success");
        })
        .catch(error => {
            // Only logout if no pw parameter in URL
            if (!searchParams.get('pw')) {
                logout();
            }

            // console.error('Error:', error);
            setServerRequestStatus("error");
        });
    };

    useEffect(getLoginUser, []);

    // Data are being loaded...
    if (serverRequestStatus === "loading") {
        return <h2>{t('data-loading')}</h2>;
    }
    
    // There was an error
    if (serverRequestStatus === "error" || !userContext["user"]) {
        return <h2>{t('data-load-error')}</h2>;
    }

    // Function to return table rows
    const renderTableRows = (group) => {
        return Object.keys(userContext.user).map((property) => {
            if (!userContext.properties.hasOwnProperty(property) || userContext.properties[property].group !== group)
                return null;
            const labelText = t('profile-' + property);
            const htmlClass = labelText.length > 30 ? "profile-label" : "profile-label-small";
            return (
                <tr key={"tr-" + group + "-" + property}>
                    <td className={htmlClass}>
                        <label>{labelText}</label>
                    </td>
                    <td>
                        <GenericInput
                            property={property}
                            propInfo={userContext.properties[property]}
                            value={userContext.user[property]}
                            axiosPostCall={axiosPostCall}
                            updateDataObject={updateDataObject}
                            specificValidate={specificValidate} />
                    </td>
                </tr>
            )
        });
    };

    //Prepare info about mandatory properties
    const mandatoryStatus = mandatoryPropsValid ?
        "profile-content-mandatory-all-okay" :
        "profile-content-mandatory-any-missing";

    const profileAccountEmail = loginUser["is_therapist"] ? (
        <p>{stringFormat(t('profile-account-email'), email)}</p>
    ) : null;
    
    //Prepare PDF link
    const pdfHealthFormLink = "/api/pdf/health-form-pdf.php" + (loginUser["is_therapist"] ? "?email=" + email : "");
    
    const extendedAccountForm = minimal ? null : (
        <React.Fragment>
            <h2>{t('profile-header-password')}</h2>
            <p>{t('profile-content-password')}</p>
            <p>{t('profile-password-requirements')}</p>
            <table>
                <tbody>{renderTableRows('password')}</tbody>
            </table>

            <h2>{t('profile-header-optional')}</h2>
            <p>{t('profile-content-optional-insurer')}</p>
            <table>
                <tbody>
                    {renderTableRows('insurer')}
                    <tr><td colSpan={2}><p>{t('profile-content-optional-therapy')}</p></td></tr>
                    {renderTableRows('therapy')}
                </tbody>
            </table>

            <h2>{t('health-form-health-header')}</h2>
            <p>{t('profile-form-health-info')}</p>
            <table>
                <tbody>{renderTableRows('health-form')}</tbody>
            </table>

            <h2>{t('agb-header')}</h2>
            <ol>
                <li>{t('agb-content-correct-and-complete')}</li>
                <li>{t('agb-content-you-represented-at-time')}</li>
                <li>{t('agb-content-therapie-time')}</li>
                <li>{stringFormat(t('agb-content-cancellation'), maxHoursCancelBeforeStart)}</li>
                <li>{t('agb-content-invoicing')}</li>
                <li>{t('agb-translation')}</li>
                <li>{t('agb-reclamation-receipt')}</li>
            </ol>

            <h2>{t('profile-pdf-header')}</h2>
            <p>{t('profile-pdf-remarks')}</p>
            <button className='pdf-open-button' onClick={() => window.open(pdfHealthFormLink)} >{t('profile-open-pdf')}</button>
            <br></br>
            <br></br>
            <h2>{t('end-page-header')}</h2>
            <button onClick={() => window.location = "/"}>{t('end-page-button')}</button>
            <br></br>
            <br></br>
            <br></br>
        </React.Fragment>
    );

    return (
        <div className="profile">
            <h2>{t('profile-header-mandatory')}</h2>
            {profileAccountEmail}
            <table>
                <tbody>{renderTableRows('basic')}</tbody>
            </table>
            <p>{t(mandatoryStatus)}</p>
            {extendedAccountForm}
        </div>
    );
}

export default Account;
