//Scripts
import React from 'react';
import { useTranslation } from 'react-i18next';
import therapies from './therapies.js';
import {  Link } from 'react-router-dom';
import ThreeDimensionWheel from './../3Dwheel/3Dwheel.js'
import LangLink from './../translations/LangLink.js'

//CSS
import './therapyList.css';

export function TherapyList() {
    const { t } = useTranslation();

    const therapyToWheelElement = (therapy) => {
        const imgOrVideo = therapy.image ? (
            <img src={therapy.image} alt={therapy.labelKey} />
        ) : (
            <video autoPlay loop muted>
                <source src={therapy.videoPath} type={therapy.videoType} />
            </video>
        );

        return (
            <LangLink key={"link-" + therapy.id} to={`/therapy?therapyid=${therapy.id}`}>
                <div className='wheel-link'>
                    {imgOrVideo}
                    <p>{t(therapy.labelKey)}</p>
                </div>
            </LangLink>
        )
    };

    return (
        <div className='slideshow-container'>
            <div className='slideshow-text'>
                <h1>{t('therapy-title')}</h1>
                {/* <p>{t('therapy-selection-message')}</p> */}
            </div>
            <div className='slideshow-wheel'>
                <ThreeDimensionWheel itemKey='therapy' items={therapies} itemToHtml={therapyToWheelElement}></ThreeDimensionWheel>
            </div>
        </div>
    );
}
