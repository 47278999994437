//Scripts
import React from 'react';
import FilterHeighlitedValue from './../../utils/filterHeighlitedValue.js'
import { getUserDisplay } from './../../utils/businessUtils.js'

//CSS
import './customerSelectElement.css';

function CustomerSelectElement({ customer, filterEmail, localFilter, setFilterEmail }) {
    return (
        <div 
            key={'customer-' + customer.email} 
            onClick={() => setFilterEmail(customer.email)}
            className={'filter-option filter-option-customer' + (filterEmail === customer.email ? ' filter-option-active' : '')}>
            <FilterHeighlitedValue 
                value={getUserDisplay(customer)}
                filter={localFilter}>
            </FilterHeighlitedValue>
        </div>
    );
}

export default CustomerSelectElement;
