import therapies from './therapies.json';

//Images
import classicMassageImage from './img/classic-massage.jpg';
import sportsMassageImage from './img/sports-massage.jpg';
import manualLymphDrainageImage from './img/manual-lymph-drainage.jpg';
import footReflexologyMassageImage from './img/foot-reflexology-massage.jpg';
import headacheTherapyImage from './img/headache-therapy.jpg';
import fullBodyMassageImage from './img/connective-tissue-massage.jpg';
import antiStressTherapyImage from './img/anti-stress-therapy.jpg';
import pregnancyMassageImage from './img/pregnancy-massage.jpg';
import electrotherapyImage from './img/electrotherapy.jpg';

therapies[0]["image"] = classicMassageImage;
therapies[1]["image"] = sportsMassageImage;
therapies[2]["image"] = manualLymphDrainageImage;
therapies[3]["image"] = footReflexologyMassageImage;
therapies[4]["image"] = headacheTherapyImage;
therapies[5]["image"] = fullBodyMassageImage;
therapies[6]["image"] = antiStressTherapyImage;
therapies[7]["image"] = pregnancyMassageImage;
therapies[8]["image"] = electrotherapyImage;

export default therapies;
