//Scripts
import { useTranslation } from 'react-i18next';
import React, { useState, useEffect, useRef } from 'react';
import CalendarWeek from './calendarWeek.js';
import { selectNextFreeTimeUnit, loadMoreWeeks, stringFormat, InMaxDisplayMonths } from './calendarLogic';
import { areDatesEqual, calendarInfoStringToDate } from '../utils/dateTimeUtils.js';
import staticCalendarInfo from './calendar-info';

//CSS
import './calendar.css';

function Calendar() {
    const { t } = useTranslation();
    const isFetchingRef = useRef(false);
    const [weeks, setWeeks] = useState([]);
    const [bookingCells, setBookingCells] = useState(null);
    const [openingDate, setOpeningDate] = useState(null);
    const [isFetching, setIsFetching] = useState(null);
    const [calendarInfo, setCalendarInfo] = useState(null);

    const fetchData = async () => {
        try {
            // Fetch dynamic data from the server
            const response = await fetch('/api/booking/calendar-info.php');
            const calendarInfoDynamic = await response.json();
            setCalendarInfo(Object.assign(staticCalendarInfo, calendarInfoDynamic));
            setIsFetching("fetched");
        } catch (error) {
            console.error('Error fetching data:', error);
            setIsFetching("error");
        }
    };

    useEffect(() => {
        // Ensure to load only once
        if (isFetchingRef.current)
            return;
        else
            isFetchingRef.current = true;
        
        fetchData();
    }, []);
    
    useEffect(() => {
        // Fetch data in interval
        const fetchDataInterval = setInterval(() => {
            fetchData();
        }, 30 * 1000);

        // Cleanup the interval on component unmount
        return () => {
            clearInterval(fetchDataInterval);
        };
    }, []);
    
    useEffect(() => {
        if (!isFetching) return;

        for (var i = 0; i < 22; i++) {
            loadMoreWeeks(setWeeks, calendarInfo);
        }
        
        // Add a scroll event listener to the document
        const handleScroll = () => {
            const scrollThreshold = 200;
            if (window.innerHeight + window.scrollY >= document.body.offsetHeight - scrollThreshold)
                loadMoreWeeks(setWeeks, calendarInfo);
        };
    
        window.addEventListener('scroll', handleScroll);
    }, [isFetching]);

    function selectAppointmentEvent(event)
    {
        //Set selection
        const bookingCells = { "cellIds" : [] };
        const clickCell = event.target;
        var topCell = clickCell;
        var unitsToBook = parseInt(clickCell.getAttribute('units-to-book')) * 1;
        const output = selectNextFreeTimeUnit(clickCell, unitsToBook, calendarInfo.therapyUnitInHour, bookingCells.cellIds, calendarInfo.therapist);
        var bottomCell = clickCell;
        if (output.givenCell != null) {
            bottomCell = output.givenCell;
        }
        unitsToBook = output.unitsToBook;
        if (unitsToBook > 0)
        {
            const output = selectNextFreeTimeUnit(clickCell, unitsToBook, -calendarInfo.therapyUnitInHour, bookingCells.cellIds, calendarInfo.therapist);
            topCell = output.givenCell
        }
        
        bookingCells["top"] = topCell.getAttribute('id');
        bookingCells["bottom"] = bottomCell.getAttribute('id');

        setBookingCells(bookingCells);
    }

    function toggleWeekEvent(event)
    {
        var thElement = event.target;
        while (thElement.tagName !== 'TH' && thElement.parentNode) {
            thElement = thElement.parentNode;
        }

        var date = new Date(thElement.getAttribute('date'));
        if (date)
        {
            var daysBackToMonday = -(date.getDay() - 1) % 7 + 1;
            date = new Date(
                date.getFullYear(), 
                date.getMonth(), 
                date.getDate() + daysBackToMonday);
        }

        if (openingDate != null && areDatesEqual(openingDate, date))
        {
            setBookingCells(null);
            date = null;
        }

        setOpeningDate(date);
    }
    
    // Merge dynamic data with static data
    calendarInfoStringToDate(calendarInfo);

    if (!isFetching) {
        return <h2>{t("data-loading")}</h2>
    }

    if (isFetching === "error") {
        return <h2>{t("data-load-error")}</h2>
    }

    if (weeks == null || weeks.length <= 0) {
        return (
            <React.Fragment>
                <h2>{t("data-none")}</h2>
                <p>{t("time-calendar-empty")}</p>
            </React.Fragment>
        )
    }

    const months = [];
    let currentMonth = [];
    weeks.forEach((week) => {
        if (week.newMonthText !== "") {
            if (currentMonth.length > 0) {
                months.push(currentMonth);
            }
            currentMonth = [week];
        } else {
            currentMonth.push(week);
        }
    });

    if (currentMonth.length > 0) {
        months.push(currentMonth);
    }

    return months.map((month, monthIndex) => {
        const maxMonthInfo = month.some(week => !InMaxDisplayMonths(calendarInfo, week.endDate)) ? (
            <div className='calendar-max-months-info'>{stringFormat(t("calendar-max-months-info"), calendarInfo.maxMonthsToShow)}</div>
        ) : null;
        return (
            <div key={"div-" + monthIndex} className='calendar'>
                <h1>{t(month[0].newMonthText)} {month[0].startDate.getFullYear()}</h1>
                <table>
                    <tbody>
                    {month.map((week, weekIndex) => (
                        <CalendarWeek 
                            key={"cw-" + monthIndex + "-" + weekIndex} 
                            startDate={week.startDate} 
                            endDate={week.endDate} 
                            calendarInfo={calendarInfo}
                            bookingCells={bookingCells} 
                            selectAppointmentEvent={selectAppointmentEvent}
                            toggleWeekEvent={toggleWeekEvent}
                            hidden={openingDate == null ? true : (week.startDate < openingDate && openingDate < week.endDate) ? !week.hidden : week.hidden}
                        />
                    ))}
                    </tbody>
                </table>
                {maxMonthInfo}
            </div>
        )
    })
}

export default Calendar;
