//Scripts
import React, { useState } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { decimalHourToTimeString } from '../../utils/dateTimeUtils.js';
import { stringFormat } from '../../calendar/calendarLogic.js';
import DayTimePlanGraphe from './day-time-plan-graphe.js';

//CSS
import './weekplan.css';

function WeekPlan({ logout, weekplans, setWeekPlan }) {
    const [formDataForRename, setFormDataForRename] = useState({});
    const [formDataForWeekAdd, setFormDataForWeekAdd] = useState({});
    const [formDataForBreakAdd, setFormDataForBreakAdd] = useState({});
    const [selectedPlanDayTime, setSelectedPlanDayTime] = useState(null);
    const { t } = useTranslation();

    const weekPlanThenCatch = (axiosPost) => {
        axiosPost
            .then(setWeekPlan)
            .catch(error => {
                console.error('Error:', error);
                logout();
            });
    }

    const addWeekPlan = () => {
        const renameInfo = { "type": "addWeekPlan" };
        weekPlanThenCatch(axios.post('/api/therapist/weekplan.php', renameInfo));
    }

    const removeWeekPlan = (weekplanId) => {
        const renameInfo = { "type": "removeWeekPlan", weekplanId };
        weekPlanThenCatch(axios.post('/api/therapist/weekplan.php', renameInfo));
    }

    const onWeekPlanHeaderBlur = (weekplanId) => {
        const renameInfo = { "type": "renameWeekPlan", weekplanId, value: formDataForRename[weekplanId] };
        weekPlanThenCatch(axios.post('/api/therapist/weekplan.php', renameInfo));
    };

    const removeplanDayTimeAssignment = (planDayTimeId) => {
        const removeInfo = {"type": "removePlanDayTime", planDayTimeId };
        weekPlanThenCatch(axios.post('/api/therapist/weekplan.php', removeInfo));
    };

    const addTimeFrame = (weekPlanId, e) => {
        e.preventDefault();
        if (!formDataForWeekAdd || !formDataForWeekAdd[weekPlanId]) return;
        const newTimeFrame = formDataForWeekAdd[weekPlanId];
        if (!newTimeFrame["hour_from"] || !newTimeFrame["hour_to"]) return;
        if (!Number(newTimeFrame["hour_from"]) || !Number(newTimeFrame["hour_to"])) return;
        newTimeFrame["type"] = "addTimeFrame";
        newTimeFrame["weekPlanId"] = weekPlanId;
        weekPlanThenCatch(axios.post('/api/therapist/weekplan.php', newTimeFrame))
    };

    const addFlexBreak = (e) => {
        e.preventDefault();
        if (!selectedPlanDayTime || !selectedPlanDayTime.id)
        {
            return;
        }
        if (!formDataForBreakAdd || !formDataForBreakAdd["flex_break_start"] || !formDataForBreakAdd["preak_duration"]) {
            return;
        }

        const newFlexBreak = { type: "addFlexBreak", PlanDayTimeId: selectedPlanDayTime.id, flex_break_start: formDataForBreakAdd.flex_break_start, preak_duration: formDataForBreakAdd.preak_duration };
        weekPlanThenCatch(axios.post('/api/therapist/weekplan.php', newFlexBreak))
    }

    const removePlanFlexBreak = (flexBreakId) => {
        const removeInfo = {"type": "removeFlexBreak", flexBreakId };
        weekPlanThenCatch(axios.post('/api/therapist/weekplan.php', removeInfo));
    };

    const onWeekPlanHeaderChange = (weekplanId, e) => {
        formDataForRename[weekplanId] = e.target.value;
        setFormDataForRename(formDataForRename);
    };

    const propValueChangeWeekPlan = (weekPlanId, property, value) => {
        if (property === "hour_to" || property === "hour_from") {
            value = value * 1;
            if (value < 6) {
                value = 6;
            } else if (value > 22) {
                value = 22;
            }
        }
        if (!formDataForWeekAdd[weekPlanId]) {
            formDataForWeekAdd[weekPlanId] = { "weekDayId": 1 };
        }
        formDataForWeekAdd[weekPlanId][property] = value;
        setFormDataForWeekAdd(formDataForWeekAdd);
    };
    
    const onWeekplanInputChange = (weekPlanId, e) => {
        e.target.focus(); //required together with onBlur to take effect
        const { name, value, nodeName } = e.target;
        if (nodeName === "SELECT") {
            const selectedOption = e.target.options[e.target.selectedIndex];
            const selectedOptionId = selectedOption.getAttribute("value");
            propValueChangeWeekPlan(weekPlanId, name, selectedOptionId);
        } else {
            propValueChangeWeekPlan(weekPlanId, name, value);
        }
    };

    const onBreakInputChange = (e) => {
        const { name, value, nodeName } = e.target;
        if (nodeName === "SELECT") {
            const selectedOption = e.target.options[e.target.selectedIndex];
            const selectedOptionId = selectedOption.getAttribute("value");
            propValueChangePlanDayTime(name, selectedOptionId);
        } else {
            propValueChangePlanDayTime(name, value);
        }
    }

    const propValueChangePlanDayTime = (property, value) => {
        setFormDataForBreakAdd(prevState => ({
            ...prevState,
            [property]: value
        }));
    };

    const planDayTimeSum = (planDayTimes) => {
        var hourSum = 0;
        planDayTimes.forEach(planDayTime => 
        {
            let breakTime = 0;
            if (planDayTime["flexBreaks"] && Array.isArray(planDayTime.flexBreaks)) {
                breakTime = planDayTime.flexBreaks.reduce((sum, breakItem) => sum + breakItem.preak_duration, 0);
            }

            hourSum += planDayTime.hour_to - planDayTime.hour_from - breakTime;
        });
        return hourSum;
    };

    const selectPlanDayTime = (planDayTimeId) => {
        const planDayTimes = weekplans.flatMap(weekplan => 
            weekplan && weekplan.weekdays ? 
                weekplan.weekdays.flatMap(weekday => 
                    weekday && weekday.planDayTimes ? 
                        weekday.planDayTimes.filter(planDayTime => planDayTime && planDayTime.id === planDayTimeId)
                            .map(planDayTime => ({ ...planDayTime, weekdayName: weekday.name }))
                        : []
                )
                : []
        ).filter(Boolean);

        const planDayTime = planDayTimes[0];
        setSelectedPlanDayTime(planDayTime);

        if (!formDataForBreakAdd || Object.keys(formDataForBreakAdd).length <= 0 || formDataForBreakAdd.flex_break_start < planDayTime.hour_from + 0.5 || formDataForBreakAdd.flex_break_start > planDayTime.hour_to) {
            let hourDiff = (planDayTime.hour_to - planDayTime.hour_from) / 2;
            hourDiff = parseInt(hourDiff * 2) / 2;
            setFormDataForBreakAdd({ flex_break_start: planDayTime.hour_from + hourDiff, preak_duration: 0.5 });
        }
    };

    const weekplansHtml = weekplans
    .sort((weekplanA, weekplanB) => { 
        const dateA = new Date(weekplanA["creation_date"]);
        const dateB = new Date(weekplanB["creation_date"]);
        return dateA < dateB;
    })
    .map((weekplan) => {
        const allowEdit = weekplan.therapeut_plan_usage <= 0;
        let addTimeFrameDialogue = null;
        if (allowEdit) {
            let addFlexBreakDialogue = null;
            if (selectedPlanDayTime && (selectedPlanDayTime.hour_from + 1.5 < selectedPlanDayTime.hour_to)) {
                const breakTimes = [];
                for (let time=selectedPlanDayTime.hour_from + 0.5; time<selectedPlanDayTime.hour_to - 1; time+=0.5)
                {
                    breakTimes.push({value: time, text: decimalHourToTimeString(time)});
                }

                addFlexBreakDialogue = (
                    <form onSubmit={(e) => {addFlexBreak(e)}} className='add-timeFrame-dialogue'>
                        <span>{t('therapist-plan-flex-selection-for') + t("times-" + selectedPlanDayTime.weekdayName + "-full") + " " + t("time-weekplan-add-timeFrame2") + " " + decimalHourToTimeString(selectedPlanDayTime.hour_from) + " " + t("time-weekplan-add-timeFrame3") + " " + decimalHourToTimeString(selectedPlanDayTime.hour_to)}</span>
                        <br></br>
                        <span>{t("therapist-plan-flex-break1")} </span>
                        <select name="flex_break_start" defaultValue={formDataForBreakAdd ? formDataForBreakAdd.flex_break_start : null} onChange={(e) => {onBreakInputChange(e);}}>
                            { breakTimes.map(plan => (
                                <option key={"flex_break_start-" + plan.value} id={plan.value} value={plan.value}>{plan.text}</option>
                            )) }
                        </select>
                        <span>{t("therapist-plan-flex-break2")} </span>
                        <select name="preak_duration" defaultValue={0.5} onChange={(e) => {onBreakInputChange(e);}}>
                            {[0.5, 1, 1.5, 2, 2.5, 3].map(duration => (
                                <option key={"preak_duration-" + duration} id={duration} value={duration}>
                                    {decimalHourToTimeString(duration)}
                                </option>
                            ))}
                        </select>
                        <button type="submit">{t("therapist-plan-flex-break3")}</button>
                    </form>
                )
            }

            addTimeFrameDialogue = ( 
                <React.Fragment>
                    <form onSubmit={(e) => {addTimeFrame(weekplan.id, e)}} className='add-timeFrame-dialogue'>
                        <span>{t("time-weekplan-add-timeFrame1")} </span>
                        <select onChange={(e) => {onWeekplanInputChange(weekplan.id, e);}} name="weekDayId" >
                            {weekplan.weekdays.map((weekday) => <option key={weekday.id} id={weekday.id} value={weekday.id}>{t("times-" + weekday.name + "-full")}</option>)}
                        </select>
                        <span> {t("time-weekplan-add-timeFrame2")} </span>
                        <input onChange={(e) => {onWeekplanInputChange(weekplan.id, e);}} name="hour_from" type="number" step="0.5"></input>
                        <span> {t("time-weekplan-add-timeFrame3")} </span>
                        <input onChange={(e) => {onWeekplanInputChange(weekplan.id, e);}} name="hour_to" type="number" step="0.5"></input>
                        <button type="submit">{t("time-weekplan-add-timeFrame4")}</button>
                    </form>
                    { addFlexBreakDialogue }
                </React.Fragment> );
        }
        
        const weekPlanHeadRow = (
            <thead>
                <tr>
                    <th>{t('time-weekplan-weekday')}</th>
                    <th>{t('time-weekplan-timing')}</th>
                    <th>{t('time-weekplan-hours')}</th>
                </tr>
            </thead>
        );

        var totalWeekHours = 0;
        const weekPlanRows = weekplan.weekdays
                .sort((weekdayA, weekdayB) => { 
                    const idA = weekdayA.id === 0 ? 7 : weekdayA.id;
                    const idB = weekdayB.id === 0 ? 7 : weekdayB.id;
                    return idA - idB;
                }).map((weekday) => {
            var timeFrameEditButtons = null;

            var weekdaySchedules = null;
            if (weekday["planDayTimes"] && weekday["planDayTimes"].length > 0) {
                weekdaySchedules = weekday.planDayTimes
                    .sort((planDayTimeA, planDayTimeB) => {
                        return planDayTimeA.hour_from - planDayTimeB.hour_from
                    })
                    .map((planDayTime) => {
                        const hourFromString = decimalHourToTimeString(planDayTime.hour_from);
                        const hourToString = decimalHourToTimeString(planDayTime.hour_to);
                        if (allowEdit) {
                            const timeFrameForBreakSelection = planDayTime.hour_to - planDayTime.hour_from > 1.5 ?
                            (
                                <div className='timeFrame-action-container'>
                                    <button className='timeFrame-action' onClick={() => selectPlanDayTime(planDayTime.id)}>
                                        {t('therapist-plan-select-frame')}
                                    </button>
                                </div>
                            ) : null;
                            timeFrameEditButtons = (
                                <React.Fragment>
                                    <div className='timeFrame-action-container'>
                                        <button className='timeFrame-action' onClick={() => removeplanDayTimeAssignment(planDayTime.id)}>{t('delete')}</button>
                                    </div>
                                    {timeFrameForBreakSelection}
                                </React.Fragment>
                            )
                        }

                        let flexBreakList = null;
                        if (planDayTime.flexBreaks && planDayTime.flexBreaks.length > 0) {
                            flexBreakList = planDayTime.flexBreaks
                                .sort((flexBreakA, flexBreakB) => { return flexBreakA.flex_break_start > flexBreakB.flex_break_start })
                                .map((flexBreak) => {
                                return (
                                    <div className='time-frame-container' key={ weekplan.id + "-break-" + flexBreak.id }>
                                        {allowEdit ? (
                                            <div className='timeFrame-action-container'>
                                                <button className='timeFrame-action' onClick={() => removePlanFlexBreak(flexBreak.id)}>
                                                    {t('delete')}
                                                </button>
                                            </div>
                                        ) : null}
                                        <div className='time-frame-text'>
                                            {t('therapist-plan-flex-break-start')}
                                            {decimalHourToTimeString(flexBreak['flex_break_start'])}
                                            {t('therapist-plan-flex-break-duration')}
                                            {decimalHourToTimeString(flexBreak['preak_duration'])}
                                        </div>
                                    </div>
                                );
                            });
                        }

                        return (
                            <React.Fragment key={"planDayTimeid-" + planDayTime.id}>
                                <div className='time-frame-container' key={ weekplan.id + "-plan-day-time-" + planDayTime.id }>
                                    {timeFrameEditButtons}
                                    <div className='time-frame-text'>
                                        {stringFormat(t("therapist-plan-work-range"), hourFromString, hourToString)}
                                    </div>
                                </div>
                                {flexBreakList}
                            </React.Fragment>
                        );
                    });
            } else {
                weekdaySchedules = [t("time-weekplan-free")];
            }

            const actualDayHours = weekday['planDayTimes'] && weekday.planDayTimes.length > 0 ? planDayTimeSum(weekday.planDayTimes) : 0;
            totalWeekHours += actualDayHours;

            return (
                <tr key={"weekday-" + weekday.id}>
                    <td>{t("times-" + weekday.name + "-full")}</td>
                    <td>
                        {weekdaySchedules}
                        <DayTimePlanGraphe planDayTimes={weekday.planDayTimes}></DayTimePlanGraphe>
                    </td>
                    <td>{actualDayHours}</td>
                </tr>
            );
        });

        const hoursPerWeek = 40;
        const percentText = "⇒ " + Number((totalWeekHours / hoursPerWeek) * 100).toFixed(2) + "%";
        const weekTotalRow = (
            <tr className='time-weekplan-total'>
                <td>{t("time-weekplan-total")}</td>
                <td></td>
                <td>
                    <div>{stringFormat(t("time-weekplan-val-of-val"), totalWeekHours, hoursPerWeek)}</div>
                    <div>{percentText}</div>
                </td>
            </tr>
        );

        return (
            <fieldset key={"weekplan-" + weekplan.id}>
                <legend>
                    <input 
                        className='time-weekplan-header-input' 
                        onChange={(e) => {onWeekPlanHeaderChange(weekplan.id, e);}} 
                        onBlur={() => {onWeekPlanHeaderBlur(weekplan.id)}}
                        defaultValue={weekplan.name} />
                    {allowEdit ? 
                        <button className='weekplan-remove-button' onClick={() => removeWeekPlan(weekplan.id)}>
                            {t('delete')}
                        </button>
                        : null}
                </legend>
                {addTimeFrameDialogue}
                <table key={"weekplan-table-" + weekplan.id} className='time-weekplan-table'>
                    {weekPlanHeadRow}
                    <tbody>
                        {weekPlanRows}
                        {weekTotalRow}
                    </tbody>
                </table>
            </fieldset>
        );
    });

    return (
        <React.Fragment>
            <h2>{t("time-weekplan-header")} ({weekplans.length})</h2>
            <button className='time-weekplan-add' onClick={addWeekPlan}>{t("time-weekplan-add")}</button>
            {weekplansHtml}
        </React.Fragment>
    )
}

export default WeekPlan;
