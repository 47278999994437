//Scripts
import React, { useState, useRef, useEffect } from 'react';
import Modal from 'react-modal';

import { useTranslation } from 'react-i18next';
import calendarInfo from './../calendar/calendar-info.json';
import { addHours } from './../utils/dateTimeUtils.js';

//CSS
import './bookingActions.css';

function BookingActions({ type, booking, cancelBooking }) {
    const { t } = useTranslation();
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [modalPosition, setModalPosition] = useState({ top: 0, left: 0 });
    const buttonRef = useRef(null);

    const openModal = () => {
        if (buttonRef.current) {
            const buttonRect = buttonRef.current.getBoundingClientRect();
            setModalPosition({
                top: buttonRect.bottom + window.scrollY,
                left: buttonRect.left + window.scrollX,
            });
        }
        setModalIsOpen(true);
    };

    const closeModal = () => {
        setModalIsOpen(false);
    }; 
    
    useEffect(() => {
        let intervalId;
        if (modalIsOpen) {
            intervalId = setInterval(() => {
                if (buttonRef.current) {
                    const buttonRect = buttonRef.current.getBoundingClientRect();
                    setModalPosition({
                        top: buttonRect.bottom + window.scrollY,
                        left: buttonRect.left + window.scrollX,
                    });
                }
            }, 30);
            setTimeout(() => {
                clearInterval(intervalId);
            }, 5000);
        }
    
        return () => {
            clearInterval(intervalId);
        };
    }, [modalIsOpen]);

    const actions = [];
    if (type === "future") {
        const { maxHoursCancelBeforeStart } = calendarInfo;
        const dateBooking = new Date(booking["date"]);
        const dateCancelLimit = addHours(new Date(), maxHoursCancelBeforeStart);
        if (dateBooking >= dateCancelLimit) {
            actions.push({
                "label": "booking-cancel",
                "onClick": () => cancelBooking(booking["id"], false)
            });
        }
    }

    if (booking["reactivate_booking"]) {
        actions.push({
            "label": "booking-prop-reactivate_booking",
            "onClick": () => cancelBooking(booking["id"], true)
        });
    }

    if (actions.length <= 0) {
        return <div>-</div>;
        //{t("booking-no-edit-options")}
    }

    if (actions.length === 1) {
        const action = actions[0];
        return ( 
            <button key="1" onClick={action.onClick}>
                {t(action.label)}
            </button>
        );
    }

    const heightCalculated = actions.length * 50;
    const customStyles = {
        content: {
            position: 'absolute',
            top: `${modalPosition.top - 30 - (heightCalculated / 2)}px`,
            left: `${modalPosition.left + 30}px`,
            width: '300px',
            height: heightCalculated + 'px',
            backgroundColor: 'rgba(255, 255, 255, 0)',
            //backgroundColor: '#f5f5dc99',
            border: 'none',
            "border-radius": "10px",
        },
        overlay: {
            backgroundColor: 'rgba(255, 255, 255, 0)'
        }
    };

    return (
        <div>
            <button className="open-actions" ref={buttonRef} onClick={openModal}>...</button>
            <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                contentLabel="Example Modal"
                style={customStyles}>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        {actions.map((action, index) => (
                        <button className='booking-action' key={index} onClick={action.onClick}>
                            {t(action.label)}
                        </button>
                        ))}
                    </div>
            </Modal>
        </div>
    );
}

export default BookingActions;