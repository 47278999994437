// Libraries
import { useState } from "react";
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { t } from "i18next";
import LangLink from "../../translations/LangLink.js";
import voucherSujets from '../../vouchers/voucherPayments.json';
import voucherPayments from '../../vouchers/voucherSujets.json';
import VoucherList from './voucherList.js'

//CSS
import './voucherManagement.css';

function VoucherManagement({loginUser, logout}) {
    const { t } = useTranslation();

    return (
        <div>
            <h2>{t('voucher-management')}</h2>
            {/* <h3>{t('voucher-request')}</h3> */}
            <LangLink className='link-button' to='/vouchers'>{t('voucher-request')}</LangLink>
            <VoucherList loginUser={loginUser} logout={logout} ></VoucherList>
        </div>
    );
}

export default VoucherManagement;
