//Scripts
import React from 'react';
import { useTranslation } from 'react-i18next';
import TwoRowCustomerFilter from '../filters/twoRowCustomerFilter.js'

//CSS
import './bookingListFilter.css';

function BookingListFilter({ bookingList, activeFilter, setActiveFilterProperty }) {
    // const [customerFilter, setCustomerFilter] = useState('');
    const { t } = useTranslation();

    const getUpcommingCustomers = (list) => {
        const now = new Date();
    
        // Calculate date difference in days
        const dateDifference = (date) => {
            const bookingDate = new Date(date);
            let factor = 1;
            if (activeFilter.time === 'past' && bookingDate > now || activeFilter.time === 'future' && bookingDate < now) {
                factor = 10;
            }

            const diffTime = Math.abs(now - bookingDate) * factor;
            return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        };
        
        // Sort bookings by date difference and map to customer objects
        const sortedBookings = list
            .map(booking => ({
                ...booking,
                dateDiff: dateDifference(booking.date),
            }))
            .sort((a, b) => a.dateDiff - b.dateDiff)
            .reduce((acc, booking) => {
                if (booking.booking_type === 1) {
                    acc.push(booking);
                }
                return acc;
            }, [])
            .map(booking => ({
                email: booking.email,
                firstname: booking.firstname,
                lastname: booking.lastname,
            }));
    
        // Ensure unique values based on emails
        const uniqueCustomers = [];
        const seenEmails = new Set();
        for (const customer of sortedBookings) {
            if (!seenEmails.has(customer.email)) {
                seenEmails.add(customer.email);
                uniqueCustomers.push(customer);
            }
        }
    
        return uniqueCustomers;
    };

    const setFilterEmail = (email) => setActiveFilterProperty('filterEmail', email)
    
    return (
        <div className='filter-container'>
            <div className='filter-criteria'>
                <div className='filter-row'>
                    <div className='filter-label'>{t('cl-filter-time')}</div>
                    <div 
                        onClick={() => setActiveFilterProperty('time', 'all')}
                        className={'filter-option' + (activeFilter.time === 'all' ? ' filter-option-active' : '')}>
                        {t('cl-filter-time-all')}
                    </div>
                    <div 
                        onClick={() => setActiveFilterProperty('time', 'future')}
                        className={'filter-option' + (activeFilter.time === 'future' ? ' filter-option-active' : '')}>
                        {t('cl-filter-time-future')}
                    </div>
                    <div 
                        onClick={() => setActiveFilterProperty('time', 'past')}
                        className={'filter-option' + (activeFilter.time === 'past' ? ' filter-option-active' : '')}>
                        {t('cl-filter-time-past')}
                    </div>
                </div>
            </div>
            <div className='filter-criteria'>
                <TwoRowCustomerFilter 
                    customers={getUpcommingCustomers(bookingList)}
                    allCustomerFilterAvailable={true}
                    filterEmail={activeFilter.filterEmail}
                    setFilterEmail={setFilterEmail}
                    choicePerRow={6}>
                </TwoRowCustomerFilter>
            </div>
            <div className='filter-criteria'>
                <div className='filter-row'>
                    <div className='filter-label'>{t('cl-filter-booking-bookings-with')}</div>
                    <div 
                        onClick={() => setActiveFilterProperty('withBreaks', !activeFilter.withBreaks)}
                        className={'filter-option' + (activeFilter.withBreaks ? ' filter-option-active' : '')}>
                        <span>{t('cl-filter-booking-with-breaks')}</span>
                    </div>
                    <div 
                        onClick={() => setActiveFilterProperty('withHolidays', !activeFilter.withHolidays)}
                        className={'filter-option' + (activeFilter.withHolidays ? ' filter-option-active' : '')}>
                        <span>{t('absence-holiday')}</span>
                    </div>
                    <div 
                        onClick={() => setActiveFilterProperty('withVacations', !activeFilter.withVacations)}
                        className={'filter-option' + (activeFilter.withVacations ? ' filter-option-active' : '')}>
                        <span>{t('absence-vacations')}</span>
                    </div>
                    <div 
                        onClick={() => setActiveFilterProperty('withOtherAbsences', !activeFilter.withOtherAbsences)}
                        className={'filter-option' + (activeFilter.withOtherAbsences ? ' filter-option-active' : '')}>
                        <span>{t('cl-filter-booking-with-other-absences')}</span>
                    </div>
                </div>
            </div>
            <div className='filter-criteria'>
                <div className='filter-row'>
                    <div className='filter-label'>{t('cl-filter-booking-cancellation')}</div>
                    <div 
                        onClick={() => setActiveFilterProperty('cancellation', 'all')}
                        className={'filter-option' + (activeFilter.cancellation === 'all' ? ' filter-option-active' : '')}>
                        <span>{t('cl-filter-booking-cancellation-all')}</span>
                    </div>
                    <div 
                        onClick={() => setActiveFilterProperty('cancellation', 'scheduled')}
                        className={'filter-option' + (activeFilter.cancellation === 'scheduled' ? ' filter-option-active' : '')}>
                        <span>{t('cl-filter-booking-cancellation-scheduled')}</span>
                    </div>
                    <div 
                        onClick={() => setActiveFilterProperty('cancellation', 'cancellation')}
                        className={'filter-option' + (activeFilter.cancellation === 'cancellation' ? ' filter-option-active' : '')}>
                        <span>{t('cl-filter-booking-cancellation-cancelled')}</span>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default BookingListFilter;
