// Libraries
import { useState } from "react";
import axios from 'axios';
import { t } from "i18next";
import voucherSujets from './voucherSujets.json';
import voucherPayments from './voucherPayments.json';
import LoadTwoRowCustomerFilter from './../therapist/filters/loadTwoRowCustomerFilter.js'

// CSS
import "./vouchers.css";

// Components
import Account from "../login/account";

// Images
import Gift from "./img/gift.svg";
import Presents from "./img/presents.svg";
import Presents2 from "./img/present2.svg";
// import apple from "./img/applepay.png";
// import google from "./img/googlepay.png";
// import twint from "./img/twint.png";
// import mastercard from "./img/mastercard.png";

export default function Vouchers({ loginUser, updateLoginUser, logout, setPopup }) {
  const [currentStep, setCurrentStep] = useState(1);
  const [formData, setFormData] = useState({
    paiedBy: null,
    voucherFrom: loginUser == null ? null : loginUser["firstname"], // + " " + loginUser["lastname"]
    voucherTo: null,
    voucherAmount: null,
    sujet: 0,
    payment: 0
  });
  const [voucherRequestSent, setVoucherRequestSent] = useState(false);

  const handleNext = () => {
    if (loginUser == null) {
      setPopup("login");
    }
    setCurrentStep((prevStep) => prevStep + 1);
  };

  const handleBack = () => {
    setCurrentStep((prevStep) => prevStep - 1);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const cashTransactionsUrl = `/api/voucher.php?action=request`;
    axios.post(cashTransactionsUrl, formData)
    .then((data) => {
      if (data.status === "success") {
        console.log(data.status);
      }
      setVoucherRequestSent(true);
    })
    .catch(error => {
        console.error('Error:', error);
    });
  };

  const handlePropertyValueChange = (property, value) => {
    setFormData({ ...formData, [property]: value });
  };

  const handleChange = (e) => {
    e.target.focus(); //required together with onBlur to take effect
    const { name, value, nodeName } = e.target;
    if (nodeName === "SELECT") {
        const selectedOption = e.target.options[e.target.selectedIndex];
        const selectedOptionId = selectedOption.getAttribute("value");
        handlePropertyValueChange(name, selectedOptionId);
    } else {
        handlePropertyValueChange(name, value);
    }
  };

  const selectedPayment = voucherPayments.find(payment => payment.id === Number(formData.payment));

  if (voucherRequestSent) {
    return (
      <div>
        <div className="voucher-cover">
          <h1>{t('voucher-for-friends-family')}</h1>
        </div>
        <div className="surprise">
          <div className="text-box">
            <p>
              Wir haben Ihre Anfrage erhalten.
            {formData.payment == 2 ? (
              <span> Entnehmen Sie den Einzahlungsschein der zugestellten E-Mail. Sollten Sie die E-Mail innert Minuten nicht bekommen haben, prüfen Sie bitte Ihren Spam-Filter.</span>
            ) : (
              <span> Zur Bestätigung dessen haben wir Ihnen eine E-Mail geschickt. Sollten Sie die E-Mail innert Minuten nicht bekommen haben, prüfen Sie bitte Ihren Spam-Filter.</span>
            )}
            </p>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div>
      <div className="voucher-cover">
        <h1>{t('voucher-for-friends-family')}</h1>
      </div>
      <div className="surprise">
        <div className="surprise-left">
          <div className="text-box">
            <h1>{t('voucher-health-wellbeing')}</h1>
            <h2>{t('voucher-gift-experience')}</h2>
            <p>{t('voucher-online-payment-not-available')}</p>
          </div>
          {/* <div className="method-box">
            <div className="method-list">
              <img src={twint} alt="payment method twint" />
              <img src={mastercard} alt="payment method mastercard" />
              <img src={google} alt="payment method google" />
              <img src={apple} alt="payment method apple" />
            </div>
          </div> */}
        </div>
        <div className="surprise-right">
          <img src={Gift} alt="gift voucher"></img>
        </div>
      </div>
      <div className="voucher-form">
        <img id="present" src={Presents} alt="presents gifts massage" />
        <img id="present2" src={Presents2} alt="presents gifts massage" />
        <form onSubmit={handleSubmit}>
          {currentStep === 1 && (
            <div className="slide">
              <h1>{t('voucher-request')}</h1>

              <div className="form-group">
                <label htmlFor="voucher-from">{t('voucher-from-name')}</label>
                <input
                  type="text"
                  id="voucher-from"
                  name="voucherFrom"
                  value={formData.voucherFrom || ''}
                  onChange={handleChange}
                />
              </div>

              <div className="form-group">
                <label htmlFor="voucher-to">{t('voucher-for-name')}</label>
                <input 
                  type="text" 
                  id="voucher-to" 
                  name="voucherTo" 
                  value={formData.voucherTo || ''}
                  onChange={handleChange} />
                <p className="left">{t('voucher-optional-name-note')}</p>
              </div>

              <div className="form-group">
                <label htmlFor="sujet">{t('voucher-subject')}</label>
                <select 
                  id="sujet" 
                  name="sujet" 
                  onChange={handleChange} 
                  value={formData.sujet || 0}
                  required>
                  {voucherSujets.map((sujet) => (
                    <option key={"sujet-" + sujet.id} value={sujet.id}>
                      {t(sujet.label)}
                    </option>
                  ))}
                </select>
              </div>
              
              <div className="form-group">
                <label htmlFor="voucher-amount">{t('voucher-value-chf')}</label>
                <input
                  type="number"
                  id="voucher-amount"
                  name="voucherAmount"
                  value={formData.voucherAmount || ''}
                  onChange={handleChange}
                  required
                />
              </div>

              <div className="form-group">
                <label htmlFor="payment">{t('voucher-payment')}</label>
                <select 
                  id="payment" 
                  name="payment" 
                  onChange={handleChange} 
                  value={formData.payment}
                  required>
                  {voucherPayments.map((payment) => (
                    <option key={payment.id} value={payment.id}>
                      {t(payment.label)}
                    </option>
                  ))}
                </select>
                {selectedPayment && selectedPayment.description && (
                  <p className="left">{t(selectedPayment.description)}</p>
                )}
                {loginUser["is_therapist"] ? (
                  <div className="form-group">
                    <label htmlFor="payment">{t('voucher-buyer')}</label>
                    <p>Nur Sie als Therapeut können einen Gutschein-Käufer wählen.</p>
                    <LoadTwoRowCustomerFilter 
                      loginUser={loginUser}
                      logout={logout}
                      allCustomerFilterAvailable={false}
                      filterEmail={formData.paiedBy}
                      setFilterEmail={(email) => handlePropertyValueChange("paiedBy", email)}
                      choicePerRow={4}>
                    </LoadTwoRowCustomerFilter>
                  </div>
                ) : null}
              </div>
              {formData && formData["voucherAmount"] > 0 && formData["sujet"] > 0&& formData["payment"] > 0 ? (
                <button type="button" onClick={handleNext}>
                  {t('next')}
                </button>
              ) : (
                <p className="left">
                  {t('voucher-fill-required-fields')}
                </p>
              )}
            </div>
          )}

          {currentStep === 2 ? (
            loginUser == null ? (
              <div className="slide">
                <p>
                  {t('voucher-register-login')}
                </p>
                <button type="button" onClick={() => setPopup("login")}>
                  {t('login')}
                </button>
                <button type="button" onClick={handleBack}>
                  {t('back')}
                </button>
              </div>
            ) : (

            <div className="slide">
              <Account loginUser={loginUser} logout={logout} updateLoginUser={updateLoginUser} minimal={true}></Account>
              <button type="button" onClick={handleBack}>
                {t('back')}
              </button>
              <button type="submit">
                {t('submit')}
              </button>
            </div>
          )) : null}
        </form>
      </div>
    </div>
  );
}
