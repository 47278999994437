//Scripts
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toDisplayDateTime, decimalHourToTimeString } from './../../utils/dateTimeUtils.js';
import absenceTypes from './../absences/absenceTypes.json'
import arrangements from './../../therapy/arrangements.js'
import therapies from './../../therapy/therapies.js';
import PaymentList from './paymentList.js'
import { getUserDisplay } from './../../utils/businessUtils.js'
import CancelDialogue from './cancel-dialogue.js'
import { stringFormat } from '../../calendar/calendarLogic.js';
import LangLink from './../../translations/LangLink.js'

//CSS
import './bookingItem.css';

function BookingItem({ therapyOngoing, booking, loginUser, logout, loadBookingList }) {
    const [cancelDialogueByBookingId, setCancelDialogueByBookingId] = useState();
    const [paymentDialogueByBookingId, setPaymentDialogueByBookingId] = useState();
    const { t } = useTranslation();
    const actualTime = new Date();

    const therapyToDisplay = (booking) => {
        const arrangement = arrangements.find((arrangement) => arrangement.id === booking["arrangement_id"]);
        const therapy = therapies.find((therapy) => therapy.id === booking["therapy_id"]);
        return (
            <React.Fragment>
                {t(therapy.labelKey) + " "}
                {arrangement.durationInMinutes + t("time-minutes-short") + " CHF" + arrangement.priceInChf}
            </React.Fragment>
        );
    };

    var bookingCell = null;
    var htmlClass = booking["cancel_date"] ? "appointment-canceled" : "";
    if (therapyOngoing) {
        htmlClass += ' therapy-ongoing';
    }

    if (booking["booking_type"] === 1) {
        var canculationMessage = "";
        if (booking["cancel_date"]) {
            canculationMessage = t("booking-canceled-at") + " " + 
            toDisplayDateTime(new Date(booking["cancel_date"])) + " " +
            t("canculation-type-" + booking["cancel_type"]);
        }

        if (booking["email"]) {
            const openCancelDialogue = booking["sum_chf"] && booking["sum_chf"] != 0 ? null : (
                <button className='booking-list-link' onClick={() => setCancelDialogueByBookingId(booking["id"] === cancelDialogueByBookingId ? null : booking["id"])}>
                    {t("booking-cancel-dialogue")}
                </button>
            );

            const accountUrl = "/account?email=" + booking["email"];
            var urlHealthFormPdf = null;
            if (!booking["cancel_date"]) {
                if (booking["is_first_booking"]) {
                    urlHealthFormPdf = "/api/pdf/health-form-pdf.php?email=" + booking["email"];
                }
            }

            var paymentButton = null;
            const bookingDate = new Date(booking["date"]);
            if (bookingDate < actualTime && !booking["cancel_date"]) {
                const paymentText = t('payment-button') + " CHF" + (booking["sum_chf"] ?? 0)
                paymentButton = (
                    <button className='booking-list-link' onClick={() => setPaymentDialogueByBookingId(booking["id"] === paymentDialogueByBookingId ? null : booking["id"])}>
                        {paymentText}
                    </button>
                );
            }

            const urlDrawing = "/drawingBoard?email=" + booking["email"];
            const drawingLabel = booking["drawing_count"] * 1 > 0 ? "drawing-edit-button" : "drawing-add-button"; 
            const drawingButton = booking["cancel_date"] ? null : (
                <button className='booking-list-link' onClick={() => window.open(urlDrawing)}>
                    {t(drawingLabel)}
                </button>
            );

            const journalUrl = "/journal?bookingId=" + booking["id"];
            const journalButton = (
                <LangLink to={journalUrl} className='booking-list-link link-button'>{stringFormat(t('booking-list-journal'), booking['journal_count'])}</LangLink>
            );

            const openInvoice = () => {
                const url = "/api/pdf/booking-invoice.php?bookingId=" + booking.id;
                window.open(url, '_blank');
            };
            const potentialInvoicePdf = (booking) => {
                if (booking["invoice_amount"] <= 0 && booking["cache_amount"] <= 0) return null;
                if (booking["invoice_amount"] > 0) {
                    if (!booking["firstname"] || !booking["lastname"] || !booking["street"] || !booking["location"] || !booking["postal_code"])
                        return (
                            <div className='booking-list-warning'>{t("booking-list-no-invoice-info")}</div>
                        );
                    else
                        return (
                            <div>
                                <span>{t("payment-type-invoice")}:</span>
                                <button className='payment-button' onClick={e => openInvoice(booking["id"])}>
                                    {t("booking-list-invoice-open")}
                                </button>
                            </div>
                        );
                } else {
                    return (
                        <div>
                            <span>{t("invoice-receipt")}:</span>
                            <button className='payment-button' onClick={e => openInvoice(booking["id"])}>
                                {t("booking-list-invoice-open")}
                            </button>
                        </div>
                    );
                }
            };

            bookingCell = (
                <div>
                    {canculationMessage ? <p>{canculationMessage}</p> : null}
                    {openCancelDialogue}
                    <button className='booking-list-link' onClick={() => window.location.href = accountUrl}>
                        {getUserDisplay(booking)}
                    </button>
                    {urlHealthFormPdf ? (
                        <button className='booking-list-link' onClick={() => window.open(urlHealthFormPdf)}>{t("profile-open-pdf")}</button>
                        ) : null}
                    {drawingButton}
                    {journalButton}
                    {paymentButton}
                    {potentialInvoicePdf(booking)}
                </div>
            );
        }
    } else {
        const absenceType = absenceTypes.find(item => item.id === booking["booking_type"]);
        bookingCell = (
            <div className={htmlClass}>
                {t("absence-" + absenceType.label) + " " + getUserDisplay(booking)}
            </div>
        );
    }

    return (
        <div id={"booking-" + booking.id} key={"booking-item-" + booking.id} className='booking-item-container'>
            <div className={'booking-item' + (booking["cancel_date"] ? ' booking-item-canceled' : '')}>
                <div className={'booking-time ' + htmlClass}>
                    {t('time-weekplan-add-timeFrame2') + " " + decimalHourToTimeString(booking["hour_from"])}
                    <br></br>
                    {t('time-weekplan-add-timeFrame3') + " " + decimalHourToTimeString(booking["hour_to"])}
                </div>
                <div className={'booking-therapy ' + htmlClass}>
                    {booking["therapy_id"] ? therapyToDisplay(booking) : null}
                </div>
                {booking["booking_comment"] ? (
                    <div className={'booking-comments ' + htmlClass}>
                        <fieldset>
                            <legend>{t("booking-customer-comments")}</legend>
                            {booking["booking_comment"]}
                        </fieldset>
                    </div>
                ) : null}
                <div className={'booking-buttons'}>
                    {bookingCell}
                </div>
            </div>
            {paymentDialogueByBookingId === booking.id ? (
                <div className='booking-popup'>
                    <PaymentList loginUser={loginUser} logout={logout} booking={booking} loadBookingList={loadBookingList}></PaymentList>
                </div>
            ) : null}
            {booking["booking_type"] === 1 && cancelDialogueByBookingId === booking.id ? (
                <div className='booking-popup'>
                    <CancelDialogue booking={booking} logout={logout} loadBookingList={loadBookingList}></CancelDialogue>
                </div>
            ) : null}
        </div>
    )
}

export default BookingItem;
