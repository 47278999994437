//Scripts
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

//CSS
import './cachCheck.css';

//Images
import chfrappen5 from './chfrappen5.png';
import chfrappen10 from './chfrappen10.png';
import chfrappen20 from './chfrappen20.png';
import chfrappen50 from './chfrappen50.png';
import chf1 from './chf1.png';
import chf2 from './chf2.png';
import chf5 from './chf5.png';
import chf10 from './chf10.jpg';
import chf20 from './chf20.jpg';
import chf50 from './chf50.jpg';
import chf100 from './chf100.jpg';
import chf200 from './chf200.jpg';
import chf1000 from './chf1000.jpg';

function CashCheck({ transactionBalance }) {
    const { t } = useTranslation();

    // State to track the amounts associated with each unit
    const [unitToAmount, setUnitToAmount] = useState({});

    const cashCheckItems = [
        { image: chf1000, unit: 1000, imgWidth: 8 },
        { image: chf200, unit: 200, imgWidth: 8 },
        { image: chf100, unit: 100, imgWidth: 8 },
        { image: chf50, unit: 50, imgWidth: 8 },
        { image: chf20, unit: 20, imgWidth: 8 },
        { image: chf10, unit: 10, imgWidth: 8 },
        { image: chf5, unit: 5, imgWidth: 10 },
        { image: chf2, unit: 2, imgWidth: 8 },
        { image: chf1, unit: 1, imgWidth: 6.5 },
        { image: chfrappen50, unit: 0.50, imgWidth: 5 },
        { image: chfrappen20, unit: 0.20, imgWidth: 5.5 },
        { image: chfrappen10, unit: 0.10, imgWidth: 5 },
        { image: chfrappen5, unit: 0.05, imgWidth: 4.5 },
    ];

    // Function to handle the plus button click
    const handlePlusClick = (unit) => {
        setUnitToAmount((prev) => {
            const currentAmount = prev[unit] || 0;
            return { ...prev, [unit]: currentAmount + 1 };
        });
    };

    // Function to handle the minus button click
    const handleMinusClick = (unit) => {
        setUnitToAmount((prev) => {
            const currentAmount = prev[unit] || 0;
            if (currentAmount > 0) {
                return { ...prev, [unit]: currentAmount - 1 };
            } else {
                return prev; // Keep the amount at 0 or more
            }
        });
    };

    // Function to handle input change
    const handleInputChange = (unit, value) => {
        const newAmount = parseInt(value, 10); // Convert the input value to an integer
        if (!isNaN(newAmount) && newAmount >= 0) {
            setUnitToAmount((prev) => ({
                ...prev,
                [unit]: newAmount
            }));
        }
    };

    const checkItemsHtml = cashCheckItems.map(checkItem => {
        const currentAmount = unitToAmount[checkItem.unit] || 0;

        return (
            <div key={checkItem.unit}>
                <h4>CHF {checkItem.unit}</h4>
                <img style={{ width: checkItem.imgWidth + "rem" }} src={checkItem.image} alt={checkItem.unit}></img>
                
                {/* Check-Item-Controller */}
                <div className="check-item-controller">
                    <button onClick={() => handleMinusClick(checkItem.unit)}>-</button>
                    <input
                        type="number"
                        value={currentAmount}
                        onChange={(e) => handleInputChange(checkItem.unit, e.target.value)}
                    />
                    <button onClick={() => handlePlusClick(checkItem.unit)}>+</button>
                </div>
            </div>
        );
    });

    // Calculate the sum of all items
    const totalSum = Object.entries(unitToAmount).reduce((sum, [unit, amount]) => {
        return sum + (parseFloat(unit) * amount);
    }, 0);

    return (
        <div>
            <h2>Bargeld Überprüfung</h2>
            <table className='check-summary'>
                <tbody>
                    <tr>
                        <td className='bolt'>{t('debit')}</td>
                        <td>{t('cash-transaction-balance')}</td>
                        <td className='right'>{transactionBalance}</td>
                        <td>CHF</td>
                    </tr>
                    <tr>
                        <td className='bolt'>{t('credit')}</td>
                        <td>{t('cash-total-from-cash-count')}</td>
                        <td className='right'>{totalSum.toFixed(2)}</td>
                        <td>CHF</td>
                    </tr>
                    <tr>
                        <td className='bolt'>{t('difference')}</td>
                        <td>{t('cash-discrepancy-count-vs-transactions')}</td>
                        <td className='right'>{(transactionBalance - totalSum).toFixed(2)}</td>
                        <td>CHF</td>
                    </tr>
                </tbody>
            </table>
            <h2>Bargeld Zählung</h2>
            <div className='check-item-container'>
                {checkItemsHtml}
            </div>
        </div>
    );
}

export default CashCheck;
