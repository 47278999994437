//Scripts
import { useTranslation } from 'react-i18next';
import { stringFormat } from './../calendar/calendarLogic';
import calendarInfo from './../calendar/calendar-info.json';

function Conditions() {
    const { t } = useTranslation();
    const { maxHoursCancelBeforeStart } = calendarInfo;
      
    return (
        <div>
            <h2>{t('agb-header')}</h2>
            <ol>
                <li>{t('agb-content-therapie-time')}</li>
                <li>{stringFormat(t('agb-content-cancellation'), maxHoursCancelBeforeStart)}</li>
                <li>{t('agb-content-invoicing')}</li>
                <li>{t('agb-translation')}</li>
                <li>{t('agb-reclamation-receipt')}</li>
            </ol>
        </div>
    )
}

export default Conditions;
