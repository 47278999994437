import React, { forwardRef } from 'react';
import TextareaAutosize from 'react-textarea-autosize';

const SpellCheckTextareaAutosize = forwardRef((props, ref) => {
    return (
        <TextareaAutosize
            ref={ref}
            {...props}
            spellCheck={true}
        />
    );
});

export default SpellCheckTextareaAutosize;
