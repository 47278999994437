import arrangements from './arrangements.json';

//Images
import hourglass1 from './hourglass1.png';
import hourglass2 from './hourglass2.png';
import hourglass3 from './hourglass3.png';

arrangements[0]["image"] = hourglass1;
arrangements[1]["image"] = hourglass2;
arrangements[2]["image"] = hourglass3;

export default arrangements;
