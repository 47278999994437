//Scripts
import React from 'react';
import { useTranslation } from 'react-i18next';
import Login from './login.js';
import EmailRegistration from './emailRegistration.js';
import LangLink from './../translations/LangLink.js'

//CSS
import './accountOverview.css';

//Images
import logoutImg from './../img/logout.png';
import bookingImg from './../img/booking.png';
import profileImg from './../img/profile.png';


function AccountOverview({loginUser, setLoginUser}) {
    const { t } = useTranslation();

    if (!loginUser) {
        return (
            <div className='account-overview'>
                <EmailRegistration />
                <Login setLoginUser={setLoginUser} />
            </div>
        );
    }
    
    return (
        <div className='account-overview'>
            
            <div className='account-overview-item border-right'>
                <LangLink to={`/account`}>
                    <img className='icon' src={profileImg} alt="profile"></img>
                    <p className='text'>{t('profile-data')}</p>
                </LangLink>
            </div>
            <div className='account-overview-item border-right'>
                <LangLink to={`/bookings`}>
                    <img className='icon' src={bookingImg} alt="bookings"></img>
                    <p className='text'>{t('profile-bookings')}</p>
                </LangLink>
            </div>
            <div className='account-overview-item'>
                <button onClick={() => setLoginUser(null)}>
                    <img className='icon' src={logoutImg} alt="logout"></img>
                    <p className='text'>{t('profile-logout')}</p>
                </button>
            </div>
        </div>
    );
}

export default AccountOverview;
