//Scripts
import React from 'react';
import { useTranslation } from 'react-i18next';
import LangLink from './../translations/LangLink.js'

//CSS
import './aboutUsMenu.css';

//Images
import locationImg from './../img/location.png';
import therapistImg from './../img/about-us-therapist.png';
import aboutMeImg from './../img/therapistActive.png';
import SvgConditions from './../img/SvgConditions.js';

function AboutUsMenu() {
    const { t } = useTranslation();
    return (
        <div className='about-us-overview'>
            <div className='about-us-item border-right'>
                <LangLink to={`/aboutMe`}>
                    <img src={aboutMeImg} alt="about-me"></img>
                    <p className='text'>{t('about-us-about-me')}</p>
                </LangLink>
            </div>
            <div className='about-us-item border-right'>
                <LangLink to={`/therapists`}>
                    <img src={therapistImg} alt="therapist"></img>
                    <p className='text'>{t('about-us-therapist-link')}</p>
                </LangLink>
            </div>
            <div className='about-us-item border-right'>
                <LangLink to={`/location`}>
                    <img src={locationImg} alt="location"></img>
                    <p className='text'>{t('about-us-location')}</p>
                </LangLink>
            </div>
            <div className='about-us-item'>
                <LangLink to={`/conditions`}>
                    <SvgConditions color="#ffffff"></SvgConditions>
                    <p className='text'>{t('about-us-conditions')}</p>
                </LangLink>
            </div>
        </div>
    )
}

export default AboutUsMenu;
