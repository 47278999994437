//Scripts
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';

//CSS
import './languageMenu.css';

//Images
import deFlag from './../img/de.jpg';
import enFlag from './../img/en.jpg';
import ruFlag from './../img/ru.jpg';

function LanguageMenu({ changeLanguage }) {
    const { t } = useTranslation();
    const flag = { 'de' : deFlag, 'en': enFlag, 'ru': ruFlag };

    return (
        <div className="language">
          {Object.keys(i18next.options.resources).map(languageCode => (
            <a href={() => null}
              key={languageCode}
              className={`button-${languageCode}`}
              onClick={() => changeLanguage(languageCode)}>
                <div className='full-width'>
                    <img src={flag[languageCode]} alt="languageCode"></img><br></br>
                    {t("language-" + languageCode)}
                </div>
                <div className='small-width'>
                    <img src={flag[languageCode]} alt="languageCode"></img><br></br>
                    {languageCode.toLocaleUpperCase()}
                </div>
            </a>
          ))}
        </div>
    )
}

export default LanguageMenu;
