//Scripts
import React, { useState } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import SpellCheckTextareaAutosize from '../../utils/SpellCheckTextareaAutosize';
import cachTransactionTypes from './cashTransactionTypes.json'
import { toIsoDateTime } from './../../utils/dateTimeUtils'

//CSS
import './cashTransactionConsole.css';

function CashTransactionConsole({reloadTransactions}) {
    const { t } = useTranslation();
    const [formData, setFormData] = useState({
        date: toIsoDateTime(new Date()),
        cashTransactionTypeId: 40,
        transactionComments: '',
        amountInChf: 0
    });

    const handlePropertyValueChange = (property, value) => {
        setFormData({ ...formData, [property]: value });
    };
    const handleChange = (e) => {
        e.target.focus(); //required together with onBlur to take effect
        const { name, value, nodeName } = e.target;
        if (nodeName === "SELECT") {
            const selectedOption = e.target.options[e.target.selectedIndex];
            const selectedOptionId = selectedOption.getAttribute("value");
            handlePropertyValueChange(name, selectedOptionId);
        } else {
            handlePropertyValueChange(name, value);
        }
    };

    const cashTransactionsUrl = `/api/therapist/cash-transactions.php?action=add`;
    const cachTransactionAdd = async (e) => {
        e.preventDefault();
        axios.post(cashTransactionsUrl, formData)
        .then(() => {
            reloadTransactions();
        })
        .catch(error => {
            console.error('Error:', error);
        });
    }

    const cachTransactionSelfmade = cachTransactionTypes.filter(item => !item.foreignDetermination);
    const cachTransactionOptions = cachTransactionSelfmade.map(item => {
        return (
            <option key={"transaction-type-" + item.id} value={item.id}>{t(item.translationCode)}</option>
        )
    });

    return (
        <form onSubmit={cachTransactionAdd}>
            <h2>Bargeld Transaktion hinzufügen</h2>
            <div className='cash-transaction-controller'>
                <div className='cash-transaction-controller-item'>
                    <div className='cash-label'>{t('date')}</div>
                    <input name='date' type='datetime-local' defaultValue={formData.date} onChange={handleChange}></input>
                </div>
                <div className='cash-transaction-controller-item'>
                    <div className='cash-label'>{t('type')}</div>
                    <select name='cash-input cashTransactionTypeId' onChange={handleChange}>{cachTransactionOptions}</select>
                </div>
                <div className='cash-transaction-controller-item'>
                    <div className='cash-label'>{t('payment-transaction-text')}</div>
                    <SpellCheckTextareaAutosize className='cash-input transaction-comments' name='transactionComments' onChange={handleChange}></SpellCheckTextareaAutosize>
                </div>
                <div className='cash-transaction-controller-item'>
                    <div className='cash-label'>{t('payment-amount')}</div>
                    <input 
                        className='cash-input amount-in-chf' 
                        type='number' 
                        name='amountInChf' 
                        step='0.05' 
                        min='0' 
                        onChange={handleChange} />
                </div>
                {formData.amountInChf && formData.amountInChf != 0 ? (
                    <div className='cash-transaction-controller-item'>
                        <button type='submit'>{t('cash-add-transaction')}</button>
                    </div>
                ) : null}
            </div>
        </form>
    );
}

export default CashTransactionConsole;
