//Scripts
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { stringFormat } from './../calendar/calendarLogic';

// //CSS
// import './accountOverview.css';

function Login({setLoginUser}) {
    const [email, setEmail] = useState(null);
    const { t } = useTranslation();
    const [password, setPassword] = useState('');
    const [loginError, setLoginError] = useState({ count: 0, message: null });
    // const navigate = useNavigate();

    const loginUser = async (email, pw) => {
        try {
            const response = await fetch('/api/account/login.php',
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'User-Agent': navigator.userAgent,
                },
                body: JSON.stringify({ email, pw }),
            });
        
            if (response.status !== 200)
            {
                setLoginError({ count: 0, message: t("login-no-server-connection") })
                return;
            }

            const data = await response.json();
            if (data.success)
            {
                setLoginUser(data.user);
            }
            else
            {
                const newLoginError = {
                    count: loginError.count + 1,
                    message: stringFormat(t("login-invalid"), loginError.count + 1)
                };
                setLoginError(newLoginError);
            }

        } catch (error) {
            console.error('Error:', error);
        }
    }

    const validateEmail = (e) => {
        const input = e.target;
        if (input.validity.typeMismatch) {
            input.setCustomValidity(t("login-invalid-email"));
        } else {
            input.setCustomValidity(''); // Reset the validation message when the input is valid
        }
    };
    
    const emailPasswordSubmit = (e) => {
        e.preventDefault();
        loginUser(email, password);
    };

    return (
        <div className="login-and-registration">
            <h3>{t("login-with-account")}</h3>
            <form onSubmit={emailPasswordSubmit}>
                <table>
                    <tbody>
                        <tr>
                            <td>
                                <label>{t("login-email")}:</label>
                            </td>
                            <td>
                                <input type="email" value={email ?? ""} onChange={(e) => setEmail(e.target.value)} onInvalid={validateEmail} required />
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <label>{t("login-password")}:</label>
                            </td>
                            <td>
                                <input type="password" value={password ?? ""} onChange={(e) => setPassword(e.target.value)} required />
                            </td>
                        </tr>
                        <tr>
                            <td></td>
                            <td>
                                <button type="submit">{t("login-next")}</button>
                                {loginError.message ? (<p>{loginError.message}</p>) : null}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </form>
        </div>
    );
}

export default Login;
