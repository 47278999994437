//Scripts
import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import SpellCheckTextareaAutosize from './../../utils/SpellCheckTextareaAutosize.js';
import paymentTypes from './paymentTypes.json'
import arrangements from './../../therapy/arrangements.js';
import { roundHalfUp } from './../../utils/mathUtil.js';
import LoadTwoRowCustomerFilter from './../filters/loadTwoRowCustomerFilter.js'
import { getAquiredCustomerDisplay } from './../../utils/businessUtils.js'


//CSS
import './paymentList.css';

function PaymentList({loginUser, booking, logout, loadBookingList}) {
    const { t } = useTranslation();
    const isFetchingRef = useRef(false);

    const arrangement = arrangements.find((arrangement) => arrangement.id === booking["arrangement_id"]);
    const [percentDiscount, setPercentDiscount] = useState(0);
    const [paymentListData, setPaymentListData] = useState(null);
    const [showAcquiredCustomer, setShowAcquiredCustomer] = useState(false);
    const [formData, setFormData] = useState({
        bookingId: booking.id,
        amount: 0,
        typeId: 1,
        transactionText: '',
        aquiredCustomerEmail: ''
    });

    const loadPaymentDialogueByBooking = async (boolindId) => {
        try {
            const bookingInfoUrl = `/api/therapist/payment-by-booking.php?bookingId=` + boolindId;
            const response = await axios.get(bookingInfoUrl);
            setPaymentListData(response.data);
        } catch (error) {
            console.error('Error:', error);
            logout();
        }
    }

    const handlePercentDiscount = (e) => {
        e.target.focus(); //required together with onBlur to take effect
        setPercentDiscount(e.target.value);
    };
    const getPercentFactor = () => {
        return (100 - percentDiscount) / 100;
    };
    
    useEffect(() => {
        //Ensure to load only once
        if (isFetchingRef.current) {
            const newAmount = roundHalfUp(arrangement.priceInChf * getPercentFactor(percentDiscount), 2) - booking["sum_chf"];
            handlePropertyValueChange("amount", newAmount);
            return;
        }  else {
            isFetchingRef.current = true;
        }

        if (booking && booking.id) {
            loadPaymentDialogueByBooking(booking.id);
            const newAmount = roundHalfUp(arrangement.priceInChf * getPercentFactor(percentDiscount), 2) - booking["sum_chf"];
            handlePropertyValueChange("amount", newAmount);
        }
    }, [booking, percentDiscount]);

    const handlePropertyValueChange = (property, value) => {
        setFormData({ ...formData, [property]: value });
    };
    const handleChange = (e) => {
        e.target.focus(); //required together with onBlur to take effect
        const { name, value, nodeName } = e.target;
        if (nodeName === "SELECT") {
            const selectedOption = e.target.options[e.target.selectedIndex];
            const selectedOptionId = selectedOption.getAttribute("value");
            handlePropertyValueChange(name, selectedOptionId);
        } else {
            handlePropertyValueChange(name, value);
        }
    };

    const paymentAdd = async (e) => {
        e.preventDefault();
        axios.post('/api/therapist/payment-add.php', formData)
        .then(() => {
            loadBookingList();
            loadPaymentDialogueByBooking(booking.id);
        })
        .catch(error => {
            console.error('Error:', error);
        });
    }

    const paymentRemove = async (e, paymentId) => {
        e.preventDefault();
        axios.post('/api/therapist/payment-remove.php', {paymentId})
        .then(() => {
            loadBookingList();
            loadPaymentDialogueByBooking(booking.id);
        })
        .catch(error => {
            console.error('Error:', error);
        });
    }

    const setAquiredCustomerEmail = (email) => {
        setFormData({
            bookingId: booking.id,
            amount: -10,
            typeId: 2,
            transactionText: formData.transactionText,
            aquiredCustomerEmail: email
        });
    }
    
    if (loginUser == null) {
        return <h2>{t("data-unauthenticated")}</h2>
    }

    if (paymentListData == null) {
        return <h2>{t("data-loading")}</h2>
    }

    const anyAquisition = paymentListData.some(payment => payment.au_firstname || payment.au_lastname);
    const paymentListRows = paymentListData ? paymentListData.map((payment) => {
        const paymentType = paymentTypes.find(item => item.id === payment["type_id"]) ?? "unknown";
        return (
            <tr key={"payment-id-" + payment.id}>
                <td>
                    <button onClick={(e) => paymentRemove(e, payment["id"])}>-</button>
                </td>
                <td>{payment["amount_chf"]}</td>
                <td>{t(paymentType["label"])}</td>
                <td>{payment["transaction_text"]}</td>
                {anyAquisition ? <td>{getAquiredCustomerDisplay(payment)}</td> : null }
            </tr>
        )}
    ) : null;

    const paymentTypesOptions = paymentTypes.map((pType) => {
        return ( <option key={"payment-" + pType.id} id={pType.id} value={pType.id}>{t(pType.label)}</option>)
    });

    const paymentList = (
        <form className='payment-table' onSubmit={paymentAdd}>
            <table>
                <thead>
                    <tr>
                        <th>{t("edit-column")}</th>
                        <th>{t("payment-amount")}</th>
                        <th>{t("type")}</th>
                        <th>{t("payment-transaction-text")}</th>
                        {anyAquisition ? <th>{t("acquired-customer")}</th> : null }
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td><button type="submit">+</button></td>
                        <td className='payment-percent'>
                            <input id="percent" name="percent" type="number" value={percentDiscount} onChange={handlePercentDiscount}></input>%
                            <br></br>
                            <input id="amount" name="amount" type="number" value={formData.amount} onChange={handleChange}></input>
                        </td>
                        <td>
                            <select id="typeId" name="typeId" value={formData.typeId} onChange={handleChange}>
                                {paymentTypesOptions}
                            </select>
                        </td>
                        <td>
                            <SpellCheckTextareaAutosize id="transactionText" name="transactionText" type="text" value={formData.transactionText} onChange={handleChange} />
                        </td>
                        {anyAquisition ? <th></th> : null }
                    </tr>
                    {paymentListRows}
                </tbody>
            </table>
            {showAcquiredCustomer ? 
                (
                    <LoadTwoRowCustomerFilter
                        loginUser={loginUser}
                        logout={logout}
                        allCustomerFilterAvailable={false}
                        filterEmail={formData.aquiredCustomerEmail}
                        setFilterEmail={(email) => setAquiredCustomerEmail(email)}
                        choicePerRow={4} >
                    </LoadTwoRowCustomerFilter>
                ) : (
                    <button className='cashback-button' onClick={() => setShowAcquiredCustomer(true)}>Cashback...</button>
                )
            }
        </form>
    );
    
    return<div>
        {paymentList}
    </div>
}

export default PaymentList;