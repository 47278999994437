import React from 'react';
import { Link, useLocation } from 'react-router-dom';

const LangLink = ({ to, ...props }) => {
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const lang = params.get('lang');

    let newTo = to;

    if (typeof to === 'string') {
        const url = new URL(to, window.location.origin);
        const newSearchParams = new URLSearchParams(url.search);

        if (lang) {
            newSearchParams.set('lang', lang);
        }

        // Reconstruct the URL with new search parameters
        newTo = `${url.pathname}${newSearchParams.toString() ? `?${newSearchParams.toString()}` : ''}`;
    } else if (typeof to === 'object') {
        const newSearchParams = new URLSearchParams(to.search || location.search);

        if (lang) {
            newSearchParams.set('lang', lang);
        }

        newTo = {
            ...to,
            search: newSearchParams.toString(),
        };
    }

    return <Link to={newTo} {...props} />;
};

export default LangLink;
