//Scripts
import React, { useState, useRef } from 'react';

//CSS
import './3Dwheel.css';
// import FingerPrint from '../img/FingerPrint';
import RotationRight from '../img/RotationRight';
// import RotationLeft from '../img/RotationLeft';

//Images
// import logo from './../img/logo.png';

function ThreeDimensionWheel({ itemKey, items, itemToHtml }) { //, width, figureWidth

    // Calculate the degree step
    const degreeStep = 360 / items.length;
    const dynamicStyles = {};

    const dynamicStylesMedia = {};
    const [dragging, setDragging] = useState(false);
    const timeoutRef = useRef(null);
    const touchX = useRef(0);
    const degreeShift = useRef(0);

    const setWheelCss = (degreeChange) => {
        degreeShift.current += degreeChange;

        // Generate CSS rules dynamically based on the items amount.
        items.forEach((item, index) => {
            // const secondsPerRotation = 25;

            const degree = index * degreeStep + degreeShift.current;
            dynamicStyles[`.content-carrousel figure:nth-child(${index + 1})`] = {
                transform: `rotateY(${degree}deg) translateZ(38vw)`,
            };
            dynamicStylesMedia[`.content-carrousel figure:nth-child(${index + 1})`] = {
                transform: `rotateY(${degree}deg) translateZ(456px)`,
            };
            // dynamicStyles[`.content-carrousel figure:nth-child(${index + 1}) p`] = {
            //     animation: `flipText ${secondsPerRotation}s infinite linear ${-secondsPerRotation * (index + 1) / items.length}s;`,
            // };
        });

        // Create a style tag with dynamic styles
        if (!document) return;
        const styleTag = document.createElement('style');
        let css = '';
        for (const selector in dynamicStyles) {
            css += `${selector} {\n`;
            for (const property in dynamicStyles[selector]) {
            css += `  ${property}: ${dynamicStyles[selector][property]};\n`;
            }
            css += '}\n';
        }

        for (const selector in dynamicStylesMedia) {
            css += `@media (min-width: 1200px) { ${selector} {\n`;
            for (const property in dynamicStylesMedia[selector]) {
            css += `  ${property}: ${dynamicStylesMedia[selector][property]};\n`;
            }
            css += '}}\n';
        }

        css += ".content-carrousel:hover figure p { animation-play-state: paused; }";

        // css += ".entire-content-carrousel { width: " + width + "vw; }\n";
        // if (figureWidth) css += ".content-carrousel figure { width:" + figureWidth + "px }\n";
        if (styleTag) {
            styleTag.innerHTML = css;
        }

        // Append the style tag to the document head
        document.head.appendChild(styleTag);
    }
    setWheelCss(0);
    
    //--------------------------------

    const handleMouseMove = (event) => {
        if (dragging) {
            const deltaX = event.movementX || event.webkitMovementX || event.mozMovementX || 0;
            if (deltaX) {
                setWheelCss(deltaX * 0.2);
            }
        }
    };
    
    const handleTouchMove = (event) => {
        if (dragging) {
            const touch = event.touches[0];
            const deltaX = touch.clientX - touchX.current;
            touchX.current = touch.clientX;
            if (touchX) {
                setWheelCss(deltaX * 0.2);
            }
        }
    };
    
    const handleStartDrag = (event) => {
        if (!dragging) {
            // Pause animation
            const element = document.querySelector('.content-carrousel');
            element.style.animationPlayState = 'paused';
    
            // Start dragging
            setDragging(true);

            if (event && event.touches && event.touches.length > 0 && event.touches[0]["clientX"])
            touchX.current = event.touches[0].clientX;
        }
    };
    
    const handleEndDrag = () => {
        if (dragging) {
            // Clear the previous timeout if it exists
            if (timeoutRef.current) {
                clearTimeout(timeoutRef.current);
            }

            // Set a new timeout
            timeoutRef.current = setTimeout(() => {
                const element = document.querySelector('.content-carrousel');
                if (element)
                    element.style.animationPlayState = 'running';
            }, 1500);
            setDragging(false);
        }
    };

    //--------------------------------

    return (
        <React.Fragment>
        <section id="slideshow">
            <div className="entire-content-carrousel">
                {/* <div className='carrousel-center'>
                    <img src={logo} alt="logo"></img>
                </div> */}
                <div className="content-carrousel">
                    { items && items.length > 0 ?
                    items.map((item) => (
                        <figure className="shadow" key={itemKey + "-" + item.id}>
                            {itemToHtml(item)}
                        </figure>
                    )) : null}
                </div>
            </div>
        </section>
        <div className='rotation-controller'
            onMouseDown={handleStartDrag}
            onMouseUp={handleEndDrag}
            onMouseLeave={handleEndDrag}
            onTouchStart={handleStartDrag}
            onTouchEnd={handleEndDrag}
            onTouchCancel={handleEndDrag}
            onMouseMove={handleMouseMove}
            onTouchMove={handleTouchMove}>
                <RotationRight className='arrow-right' color={'#004400'}></RotationRight>
        </div>
        </React.Fragment>
    );
}

export default ThreeDimensionWheel;
