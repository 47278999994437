//Scripts
import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import { calculateAge, toDisplayDateWithoutTime } from './../utils/dateTimeUtils.js';
import { moveKeyAfter } from '../utils/hashAndListUtils.js';
import NewCustomerForm from './newCustomerForm.js'
import TimeDifferenceToNow from '../utils/timeDifferenceToNow.js';
import FilterHeighlitedValue from '../utils/filterHeighlitedValue.js'
import { filterClients, customerSorting } from '../utils/businessUtils.js'
import LangLink from './../translations/LangLink.js'
import { stringFormat } from '../calendar/calendarLogic.js';

//CSS
import './clientList.css';

function ClientList({loginUser, logout}) {
    const { t } = useTranslation();
    const isFetchingRef = useRef(false);
    const [customerList, setCustomerList] = useState(null);
    const [filter, setFilter] = useState("");
    const [sortOrder, setSortOrder] = useState("closest-bookings");

    const onFilterChange = (e) => {
        setFilter(e.target.value);
    }

    const onSortorderChange =(e) => {
        setSortOrder(e.target.value);
    }

    const loadClientList = async () => {
        try {
            const bookingInfoUrl = `/api/therapist/clientList.php`;
            const response = await axios.get(bookingInfoUrl);
            setCustomerList(response.data);
        } catch (error) {
            console.error('Error:', error);
            logout();
        }
    }

    useEffect(() => {
        // Ensure to load only once
        if (isFetchingRef.current)
            return;
        else
            isFetchingRef.current = true;

        loadClientList();
    }, []);
    
    if (loginUser == null) {
        return <h2>{t("data-unauthenticated")}</h2>
    }

    if (customerList == null) {
        return <h2>{t("data-loading")}</h2>
    }

    if (customerList.length <= 0) {
        return <h2>{t("data-none")}</h2>
    }

    const loadedLanguages = Object.keys(i18next.options.resources);
    const languageHash = loadedLanguages.reduce((acc, langCode) => {
        acc[langCode] = t("this-language", { lng: langCode });
        return acc;
    }, {});

    const adaptedCustomerList = customerList.map((item) => {
        const client = Object.assign({}, item);
        if (client["language_code"]) {
            client["language_code"] = languageHash[client["language_code"]];
        }
        if (client["birthday"]) {
            const date = new Date(client["birthday"])
            client["birthday"] = toDisplayDateWithoutTime(date) + " (" + calculateAge(date) + ")";
        }
        if (client['has_password'] && client['has_password'] === 1) {
            client['has_password'] = t('boolean-yes');
        }

        client["address"] = client["country_code"] && client["country_code"].length > 0 ? client["country_code"] + "-" : "";
        delete client["country_code"];
        client["address"] += (client["postal_code"] && client["postal_code"].length > 0 ? client["postal_code"] + " " : "");
        delete client["postal_code"];
        client["address"] += (client["location"] && client["location"].length > 0 ? client["location"] + " " : "");
        delete client["location"];
        client["address"] += (client["street"] && client["street"].length > 0 ? client["street"] + " " : "");
        delete client["street"];
        return moveKeyAfter(client, "address", "phone");
    });

    const propertyToWidth = {};
    adaptedCustomerList.forEach((client) => {
        Object.keys(client).forEach((property) => {
            if (!propertyToWidth.hasOwnProperty(property)){
                propertyToWidth[property] = 0;
            }
            const valuePropLength = client[property] ? client[property].length * 9.2 : 0;
            if (propertyToWidth[property] < valuePropLength) {
                propertyToWidth[property] = valuePropLength;
            }
            const labelPropLength = t("profile-" + property).length * 8.8;
            if (propertyToWidth[property] < labelPropLength) {
                propertyToWidth[property] = labelPropLength;
            }
        });
    });

    const dateNow = new Date();
    const customerListHtml = filterClients(adaptedCustomerList, filter)
        .sort((c1, c2) => { return customerSorting(c1, c2, sortOrder, dateNow); })
        .map((client, index) => {
        const propValuePairs = Object.keys(client).map((property) => {
            if (property === "id" || property === "last_booking_id" || property === "journal_count" || property === "drawing_count") return null;
    
            if (!client[property] || (property === "address" && client[property].length < 4)) {
                return null;
            }
    
            var value = client[property];
            if (property === "email") {
                const mailTo = "mailto:" + client['email'];
                value = (
                    <a href={mailTo} className='underline'>
                        <FilterHeighlitedValue value={client["email"]} filter={filter}></FilterHeighlitedValue>
                    </a>
                );
            } else if (property === "creation_date") {
                const creationDate = new Date(client["creation_date"]);
                value = ( <TimeDifferenceToNow date={creationDate}></TimeDifferenceToNow> );
            } else if (property === "closest_booking_date") {
                const creationDate = new Date(client["closest_booking_date"]);
                value = ( <TimeDifferenceToNow date={creationDate} pastOrFutureIndication={true}></TimeDifferenceToNow> );
            } else if (property === "future_bookings") {
                value = (
                    <React.Fragment>
                        <FilterHeighlitedValue value={value + " "} filter={filter}></FilterHeighlitedValue>
                        <LangLink className='underline' to={'/bookingList?time=future&cancellation=scheduled&filterEmail=' + client["email"]}>
                            {t('client-show-bookings')}
                        </LangLink>
                    </React.Fragment>
                );
            } else if (property === "past_bookings") {
                value = (
                    <React.Fragment>
                        <FilterHeighlitedValue value={value + " "} filter={filter}></FilterHeighlitedValue>
                        <LangLink className='underline' to={'/bookingList?time=past&cancellation=scheduled&filterEmail=' + client["email"]}>
                            {t('client-show-bookings')}
                        </LangLink>
                    </React.Fragment>
                );
            } else if (property === "canceled_bookings_therapist") {
                value = (
                    <React.Fragment>
                        <FilterHeighlitedValue value={value + " "} filter={filter}></FilterHeighlitedValue>
                        <LangLink className='underline' to={'/bookingList?time=past&cancellation=cancellation&filterEmail=' + client["email"]}>
                            {t('client-show-bookings')}
                        </LangLink>
                    </React.Fragment>
                );
            } else if (property === "canceled_bookings_customer") {
                value = (
                    <React.Fragment>
                        <FilterHeighlitedValue value={value + " "} filter={filter}></FilterHeighlitedValue>
                        <LangLink className='underline' to={'/bookingList?time=past&cancellation=cancellation&filterEmail=' + client["email"]}>
                            {t('client-show-bookings')}
                        </LangLink>
                    </React.Fragment>
                );
            } else {
                value = <FilterHeighlitedValue value={value} filter={filter}></FilterHeighlitedValue>
            }

            const propClass = "userProperty profile-" + property;
            return (
                <div key={"client-" + property + "-" + client.id} className={propClass} style={{ "width": propertyToWidth[property] + "px" }}>
                    <div className='userProperty-label'>{t("profile-" + property)}</div>
                    <div className='userProperty-value'>{value}</div>
                </div>
            );
        });
    
        // Set html class
        const className = "user userProp" + ((index + 1) % 2);
    
        // Set URLs
        const accountUrl = "/account?email=" + client["email"];
        const journalUrl = "/journal?bookingId=" + client["last_booking_id"];
        const urlDrawing = "/drawingBoard?email=" + client["email"];
        const drawingLabel = client["drawing_count"] > 0 ? "drawing-edit-button" : "drawing-add-button"; 

        return (
            <div key={"client-" + client.id} className={className}>
                {propValuePairs}
                <LangLink className="link" to={accountUrl}>
                    {t("booking-edit")}
                </LangLink>
                { client['journal_count'] > 0 ? 
                    <LangLink className="link" to={journalUrl}>
                        {stringFormat(t('booking-list-journal'), client['journal_count'])}
                    </LangLink>
                    : null }
                {client["drawing_count"] > 0 ?
                    <button className='link' onClick={() => window.open(urlDrawing)}>
                        {t(drawingLabel)}
                    </button>
                    : null
                }
            </div>
        );
    });

    return (
        <div className="userList">
            <div className='client-list-head'>
                <NewCustomerForm></NewCustomerForm>
                <div className='client-new-form-empty-buttons'>
                    <h3>{t('client-new-form-empty-header')}</h3>
                    <LangLink className='link-button' to='/api/pdf/health-form-pdf.php?email=empty&language_code=de' target='_blank'>
                        {t('language-de')}
                    </LangLink>
                    <LangLink className='link-button' to='/api/pdf/health-form-pdf.php?email=empty&language_code=en' target='_blank'>
                        {t('language-en')}
                    </LangLink>
                    <LangLink className='link-button' to='/api/pdf/health-form-pdf.php?email=empty&language_code=ru' target='_blank'>
                        {t('language-ru')}
                    </LangLink>
                </div>
                <div>
                    <h3>{t('clientList-filter')}</h3>
                    <input onChange={onFilterChange} name='filter'></input>
                </div>
                <div>
                    <h3>{t('clientList-order')}</h3>
                    <select 
                        className='client-sort-order'
                        onChange={onSortorderChange}
                        name='booking-by-therapist-sorting'>
                        <option value={'closest-bookings'}>{t('booking-by-therapist-sorting-closest-bookings')}</option>
                        <option value={'newest-registration'}>{t('booking-by-therapist-newest-registration')}</option>
                    </select>
                </div>
            </div>
            <h2>{t("clientList-button")} ({adaptedCustomerList.length})</h2>
            {customerListHtml}
        </div>
    );
}

export default ClientList;
