//Scripts
import React from 'react';
import calendarInfo from './../calendar/calendar-info';
import { useTranslation } from 'react-i18next';
import { addMinutes, toDisplayDateWithoutTime, toDisplayTime } from '../utils/dateTimeUtils';
import { stringFormat } from './../calendar/calendarLogic';
import { dateAndTimeForInput } from './../utils/dateTimeUtils.js'
import i18next from 'i18next';

function TherapyTable({ therapieTableData, language, dateTimeSetGet }) {
    const { t } = useTranslation();
    const lang = { "lng": language ? language : i18next.language };

    const { weekdays } = calendarInfo;
    const weekday = weekdays.find(w => w.weekDay === therapieTableData.bookingDate.getDay())
    const bookingDateValue = t("times-" + weekday.label + "-full", lang) + " " + toDisplayDateWithoutTime(therapieTableData.bookingDate);
    const therapiEndDate = addMinutes(therapieTableData.bookingDate, therapieTableData.arrangement.durationInMinutes);
    const time = stringFormat(
        t("booking-time-range", lang),
        toDisplayTime(therapieTableData.bookingDate),
        toDisplayTime(therapiEndDate));

    let dateTimeFields;
    if (dateTimeSetGet) {
        const [bookingDateTime, setBookingDateTime] = dateTimeSetGet
        const dateTimeToString = dateAndTimeForInput(bookingDateTime);
        dateTimeFields = ( 
            <tr>
                <td>
                    {t("date", lang)} {t("booking-time", lang)}:
                </td>
                <td>
                    <input type='datetime-local' value={dateTimeToString} onChange={setBookingDateTime}></input>
                </td>
            </tr>
        );
    } else {
        dateTimeFields = ( 
            <React.Fragment>
                <tr>
                    <td>{t("date", lang)}:</td>
                    <td>{bookingDateValue}</td>
                </tr>
                <tr>
                    <td>{t("booking-time", lang)}:</td>
                    <td>{time}</td>
                </tr>
            </React.Fragment>
        );
    }

    return (
        <table>
            <tbody>
                <tr>
                    <td>{t("booking-therapy", lang)}:</td>
                    <td>{t(therapieTableData.therapy.labelKey)}</td>
                </tr>
                {dateTimeFields}
                <tr>
                    <td>{t("booking-duration", lang)}:</td>
                    <td>{therapieTableData.arrangement.durationInMinutes} {t("time-minutes", lang)}</td>
                </tr>
                <tr>
                    <td>{t("booking-price", lang)}:</td>
                    <td>CHF {therapieTableData.arrangement.priceInChf}</td>
                </tr>
            </tbody>
        </table>
    );
}

export default TherapyTable;