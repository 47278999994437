import { useTranslation } from 'react-i18next';


const TimeDifferenceToNow = ({ date, pastOrFutureIndication }) => {
    const addS = (value) => {
        return value === 1 ? "" : "s";
    }

    const { t } = useTranslation();
    const now = new Date();
    const inputDate = new Date(date);
    const isInPast = now > inputDate;
    const diffMs = isInPast ? now - inputDate : inputDate - now;

    const msInMinute = 60 * 1000;
    const msInHour = 60 * msInMinute;
    const msInDay = 24 * msInHour;
    const msInWeek = 7 * msInDay;
    const msInMonth = 30 * msInDay; // Approximation for a month
    const msInYear = 365 * msInDay; // Approximation for a year

    let res = "";
    if (pastOrFutureIndication) {
        res += (isInPast ? t('pre-time-past') : t('pre-time-upcomming')) + " ";
    }

    if (diffMs < msInHour * 2) {
        const minutes = Math.floor(diffMs / msInMinute);
        const seconds = Math.floor((diffMs % msInMinute) / 1000);
        res += minutes + " " + t("time-minute" + addS(minutes)) + 
                t('time-separator') + 
                seconds + " " + t("time-second" + addS(seconds));
    } else if (diffMs < msInDay) {
        const hours = Math.floor(diffMs / msInHour);
        const minutes = Math.floor((diffMs % msInHour) / msInMinute);
        res += hours + " " + t("time-hour" + addS(hours)) + 
                t('time-separator') + 
                minutes + " " + t("time-minute" + addS(minutes));
    } else if (diffMs < msInWeek) {
        const days = Math.floor(diffMs / msInDay);
        const hours = Math.floor((diffMs % msInDay) / msInHour);
        res += days + " " + t("time-day" + addS(days)) + 
                t('time-separator') + 
                hours + " " + t("time-hour" + addS(hours));
    } else if (diffMs < msInMonth) {
        const weeks = Math.floor(diffMs / msInWeek);
        const days = Math.floor((diffMs % msInWeek) / msInDay);
        res += weeks + " " + t("time-week" + addS(weeks)) + 
                t('time-separator') + 
                days + " " + t("time-day" + addS(days));
    } else if (diffMs < msInYear * 2) {
        const months = Math.floor(diffMs / msInMonth);
        const weeks = Math.floor((diffMs % msInMonth) / msInWeek);
        res += months + " " + t("time-month" + addS(months)) + 
                t('time-separator') + 
                weeks + " " + t("time-week" + addS(weeks));
    } else {
        const years = Math.floor(diffMs / msInYear);
        const months = Math.floor((diffMs % msInYear) / msInMonth);
        res += years + " " + t("time-year" + addS(years)) + 
                t('time-separator') + 
                months + " " + t("time-month" + addS(months));
    }

    return res;
};

export default TimeDifferenceToNow;
