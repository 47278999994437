//Scripts
import { useTranslation } from 'react-i18next';

//CSS
import './therapists.css';

function Therapists() {
    const { t } = useTranslation();
    // Keep one div because the child contains display: flex. It appears like TransitionGroup can't handle it propertly / directly.
    return (
        <div>
            <div className='therapists'>
                
                <div className='therapists-item'>
                    <h2>{t('therapists-welcome-header')}</h2>
                    <p>{t('therapists-welcome-text')}</p>
                    <br></br>
                    <p style={{fontWeight: 'bold'}}>{t('about-us-phone')}</p>
                    <p>{t('about-us-phone-info')}</p>
                    <br></br>
                    <p style={{fontWeight: 'bold'}}>{t('about-us-email-general')}: <a className='underline' href="mailto:info@anatomicus.ch">info@anatomicus.ch</a></p>
                    <p>{t('about-us-email-support')}: <a className='underline' href="mailto:support@anatomicus.ch">support@anatomicus.ch</a></p>
                    <p>{t('about-us-email-albina')}: <a className='underline' href="mailto:albina@anatomicus.ch">albina@anatomicus.ch</a></p>
                    <p>{t('about-us-language')}</p>
                </div>
                
                <div className='therapists-item'>
                    <h2>{t('therapists-refund-header')}</h2>
                    <p>{t('therapists-refund-text')}</p>
                    <p>{t('therapists-refund-text2')} <a className='underline' href='https://emr.ch/partner'>EMR Partner / Versicherungen</a></p>
                </div>
            </div>
        </div>
    );
}

export default Therapists;
