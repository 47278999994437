export function IsProd() {
    return window.location.hostname === "anatomicus.ch";
}

// Function to check if the environment is test
export function IsTest() {
    return window.location.hostname === "test.anatomicus.ch";
}

// Function to check if the environment is development
export function IsDevelopment() {
    return (
        window.location.hostname === "localhost" ||
        !window.location.hostname.endsWith(".anatomicus.ch")
    );
}
