export function decimalHourToHourAndMinutes(decimalHour) {
    const hours = Math.floor(decimalHour);
    const minutes = Math.round((decimalHour - hours) * 60);
    return {hours, minutes};
}

export function dateToTimeDecimal(date) {
    return date.getHours() + date.getMinutes() / 60;
}

export function decimalHourToTimeString(decimalHour) {
    const {hours, minutes} = decimalHourToHourAndMinutes(decimalHour);
    const formattedMinutes = minutes.toString().padStart(2, '0');
    return `${hours}:${formattedMinutes}`;
}

export function toIsoDateWithoutTime(date) {
    if (!date) return "";
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear().toString();
    return `${year}-${month}-${day}`;
}

export function toIsoDateTime(date) {
    if (!date) return "";  // Return an empty string if no date is provided

    // Extract date components
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear().toString();

    // Extract time components
    const hour = date.getHours().toString().padStart(2, '0');
    const minute = date.getMinutes().toString().padStart(2, '0');

    // Format and return the ISO 8601 datetime string
    return `${year}-${month}-${day}T${hour}:${minute}`;
}

export function toDisplayDateWithoutTime(date) {
    if (!date) return "";
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear().toString();
    return `${day}.${month}.${year}`;
}

export function toDisplayDateTime(date) {
    if (!date) return "";
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear().toString();
    const hour = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    return `${day}.${month}.${year} ${hour}:${minutes}`;
}

export function toDisplayTime(date) {
    if (!date) return "";
    const hour = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    return `${hour}:${minutes}`;
}

export function addMonths(date, months) {
    if (!date) return null;
    const newDate = new Date(date);
    newDate.setMonth(newDate.getMonth() + months);
    return newDate;
}

export function addDays(date, days) {
    if (!date) return null;
    const newDate = new Date(date);
    newDate.setDate(newDate.getDate() + days);
    return newDate;
}

export function addHours(date, hoursToAdd) {
    if (!date) return null;
    const newDate = new Date(date);
    newDate.setHours(date.getHours() + hoursToAdd);
    return newDate;
}

export function addMinutes(date, minutesToAdd) {
    if (!date) return null;
    const newDate = new Date(date);
    newDate.setMinutes(date.getMinutes() + minutesToAdd);
    return newDate;
}

export function areDatesEqual(date1, date2) {
    if (!date1 || !date2) return null;
    return (
      date1.getFullYear() === date2.getFullYear() &&
      date1.getMonth() === date2.getMonth() &&
      date1.getDate() === date2.getDate()
    );
}

export function areDateTimesEqual(date1, date2) {
    if (!date1 || !date2) return null;
    return (
      date1.getFullYear() === date2.getFullYear() &&
      date1.getMonth() === date2.getMonth() &&
      date1.getDate() === date2.getDate() &&
      date1.getHours() === date2.getHours() &&
      date1.getMinutes() === date2.getMinutes() &&
      date1.getSeconds() === date2.getSeconds()
    );
}

export function calendarInfoStringToDate(calendarInfo) {
    if (calendarInfo && calendarInfo.bookings && calendarInfo.bookings.length > 0) {
        calendarInfo.bookings.forEach(bookingItem => {
            if (bookingItem.dateString && !bookingItem["date"]) {
                bookingItem["date"] = new Date(bookingItem.dateString);
            }
        });
    }
}

export function calculateAge(birthDate) {
    const currentDate = new Date();
    const dob = new Date(birthDate);
    
    let age = currentDate.getFullYear() - dob.getFullYear();
    
    // Check if the birthday has occurred this year
    const hasBirthdayOccurred = 
        currentDate.getMonth() > dob.getMonth() || 
        (currentDate.getMonth() === dob.getMonth() && currentDate.getDate() >= dob.getDate());
    
    if (!hasBirthdayOccurred) {
        age--;
    }
    
    return age;
}

export function stringDateInPast(stringDate) {
    return dateInPast(new Date(stringDate));
}

export function dateInPast(verifyDate) {
    const dateNow = new Date();
    return verifyDate < dateNow;
}

export function toMonday(date, direction) {
    if (!(date instanceof Date)) {
        throw new Error('Invalid date object');
    }

    // Calculate the number of days to add or subtract to reach Monday
    const dayOfWeek = date.getDay(); // 0 (Sunday) to 6 (Saturday)
    const daysToAdd = direction === 1 ? (8 - dayOfWeek) % 7 : (dayOfWeek === 0 ? 6 : dayOfWeek - 1);
    
    // Clone the date object to avoid mutating the original
    const newDate = new Date(date);
    
    // Modify the date by adding or subtracting days to reach Monday
    newDate.setDate(newDate.getDate() + (direction * daysToAdd));

    return newDate;
}

export function dateAndTime(date) {
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const year = date.getFullYear();

    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');

    return `${day}.${month}.${year} ${hours}:${minutes}`;
}

export function dateAndTimeForInput(date) {
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const year = date.getFullYear();

    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');

    return `${year}-${month}-${day}T${hours}:${minutes}`;
}

export function fullDayDateAndTime(t, date) {
    const dayName = weekDayDisplay(t, date);

    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const year = date.getFullYear();

    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');

    return `${dayName} ${day}.${month}.${year} ${hours}:${minutes}`;
}

export function fullDayDateWithoutTime(t, date) {
    const dayName = weekDayDisplay(t, date);

    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const year = date.getFullYear();

    return `${dayName} ${day}.${month}.${year}`;
}

export function weekDayDisplay(t, date) {
    const days = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
    const langKey = "times-" + days[date.getDay()] + "-full";
    return t(langKey);
}

export function differenceInDays(date1, date2) {
    const millisecondsPerDay = 24 * 60 * 60 * 1000; // Number of milliseconds in a day
    const differenceInMilliseconds = Math.abs(date1.getTime() - date2.getTime());
    const differenceInDays = Math.round(differenceInMilliseconds / millisecondsPerDay);
    return differenceInDays;
}

export function isToday(date) {
    const today = new Date();
    return date.getDate() === today.getDate() &&
           date.getMonth() === today.getMonth() &&
           date.getFullYear() === today.getFullYear();
}
