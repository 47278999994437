//Scripts
import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { toDisplayDateTime } from '../../utils/dateTimeUtils'
import { getUserDisplay } from '../../utils/businessUtils'
import CashCheck from './CashCheck/cashCheck'
import CashTransactionConsole from './cashTransactionConsole.js'
import cachTransactionTypes from './cashTransactionTypes.json'
import { getAquiredCustomerDisplay } from './../../utils/businessUtils.js'

//CSS
import './cashRegister.css';

function CashRegister({loginUser, logout}) {
    const { t } = useTranslation();
    const isFetchingRef = useRef(false);
    const [cashTransactions, setCashTransactions] = useState(null);

    const loadCashTransactions = async () => {
        try {
            const cashTransactionsUrl = `/api/therapist/cash-transactions.php?action=load`;
            const response = await axios.get(cashTransactionsUrl);
            setCashTransactions(response.data);
        } catch (error) {
            console.error('Error:', error);
            logout();
        }
    }

    const paymentRemove = async (e, paymentId) => {
        e.preventDefault();
        axios.post('/api/therapist/payment-remove.php', {paymentId})
        .then(() => {
            loadCashTransactions();
        })
        .catch(error => {
            console.error('Error:', error);
        });
    }

    useEffect(() => {
        // Ensure to load only once
        if (isFetchingRef.current)
            return;
        else
            isFetchingRef.current = true;
            loadCashTransactions();
    }, []);

    if (loginUser == null) {
        return <h2>{t("data-unauthenticated")}</h2>
    }

    if (cashTransactions == null) {
        return <h2>{t("data-loading")}</h2>
    }

    if (cashTransactions.length <= 0) {
        return <h2>{t("data-none")}</h2>
    }

    const cashTransactionGroups = cashTransactions.reduce((groups, transaction) => {
        // Extract the date from the transaction and create a Date object
        const date = new Date(transaction.date);
    
        // Get the month and year as a string (e.g., "January 2023")
        const monthYear = date.toLocaleString('default', { month: 'long', year: 'numeric' });
    
        // Find an existing group for this month and year
        let group = groups.find(g => g.monthYear === monthYear);
    
        // If the group doesn't exist, create it
        if (!group) {
            group = { monthYear, cashTransactions: [] };
            groups.push(group);
        }
    
        // Add the transaction to the group
        group.cashTransactions.push(transaction);
    
        return groups;
    }, []);

    const openInvoice = (bookingId) => {
        const url = "/api/pdf/booking-invoice.php?bookingId=" + bookingId;
        window.open(url, '_blank');
    };

    const cashTransactionHtml = cashTransactionGroups.map(group => {
        const firstDate = new Date(group.cashTransactions[0].date);
        return (
            <div className='cash-register-container' key={"month-year-" + group.monthYear}>
                <h4>{group.monthYear}</h4>
                <table className='cash-register-table'>
                    <thead>
                        <tr>
                            <th></th>
                            <th>{t('date')}</th>
                            <th>{t('subject')}</th>
                            <th>{t('context-info')}</th>
                            <th>{t('cash-amount-chf')}</th>
                            <th>{t('cash-balance-chf')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {group.cashTransactions.map(transaction => 
                            {
                                const cashTransactionType = cachTransactionTypes.find(item => item.id === transaction["cash_type_id"]);
                                const userDisplayName = getUserDisplay(transaction);
                                const aquiredUserDisplayName = getAquiredCustomerDisplay(transaction);
                                return (
                                    <tr key={"transaction-id-" + transaction.id}>
                                        <td>
                                            {cashTransactionType.foreignDetermination ? (
                                                <button onClick={e => openInvoice(transaction["booking_id"])}>
                                                    {t("invoice-receipt")}
                                                </button>
                                            ) : ( 
                                                <button onClick={(e) => paymentRemove(e, transaction.id)}>{t('delete')}</button>
                                            )}
                                        </td>
                                        <td>{toDisplayDateTime(new Date(transaction.date))}</td>
                                        <td>{t(cashTransactionType.translationCode)}</td>
                                        <td>
                                            <ul className='booking-context'>
                                                {userDisplayName ? (
                                                    <li>
                                                        {t('cash-from-booking-with')}: {userDisplayName}
                                                    </li>
                                                ) : null}
                                                {aquiredUserDisplayName ? (
                                                    <li>
                                                        {t('cash-for-acquisition')}: {aquiredUserDisplayName}
                                                    </li>
                                                ) : null}
                                                {transaction.transaction_text ? (
                                                    <li>
                                                        {t('payment-transaction-text')}: {transaction.transaction_text}
                                                    </li>
                                                ) : null}
                                            </ul>
                                        </td>
                                        <td className='right'>{transaction.amount_chf > 0 ? '+' : ''}{transaction.amount_chf}</td>
                                        <td className='right'>{transaction.balance}</td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
                <a className='link' target='blank' href={'/api/pdf/cashRegister.php?year=' + firstDate.getFullYear() + "&month=" + (firstDate.getMonth() + 1)}>PDF - Monatsübersicht {group.monthYear}</a>
            </div>)
        });

    return (
        <div>
            <CashTransactionConsole reloadTransactions={loadCashTransactions}></CashTransactionConsole>
            <h2>Bargeld Transaktionsliste</h2>
            {cashTransactionHtml}
            <CashCheck transactionBalance={cashTransactions[cashTransactions.length - 1].balance}></CashCheck>
        </div>
    );
}

export default CashRegister;
