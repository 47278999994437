import drawingBackList from './drawing-back.json';

//Images
import humanFront from './human-front.png';
import humanLeft from './human-left.png';
import humanBack from './human-back.png';
import humanRight from './human-right.png';
import humanSpine from './human-spine.png';

drawingBackList[1]["image"] = humanFront;
drawingBackList[2]["image"] = humanLeft;
drawingBackList[3]["image"] = humanBack;
drawingBackList[4]["image"] = humanRight;
drawingBackList[5]["image"] = humanSpine;

export default drawingBackList;
