//Scripts
import React from 'react';
import { useTranslation } from 'react-i18next';
import LangLink from './../translations/LangLink.js'
// import Login from '../login/login.js';
// import EmailRegistration from '../login/emailRegistration.js';

//CSS
import './therapistOverview.css';

function TherapistOverview() {
    const { t } = useTranslation();

    return (
        <div className='therapist-overview'>
            <div className='therapist-overview-item'>
                <LangLink to={`/voucherManagement`}>{t('voucher-therapist-button')}</LangLink>
            </div>
            <div className='therapist-overview-item'>
                <LangLink to={`/cashRegister`}>{t('cash-register')}</LangLink>
            </div>
            <div className='therapist-overview-item'>
                <LangLink to={`/operationPlan`}>{t('operations-plan')}</LangLink>
            </div>
            <div className='therapist-overview-item'>
                <LangLink to={`/absences`}>{t('absence-button')}</LangLink>
            </div>
            <div className='therapist-overview-item'>
                <LangLink to={`/clientList`}>{t('clientList-button')}</LangLink>
            </div>
            <div className='therapist-overview-item'>
                <LangLink to={`/bookingList`}>{t('bookingList-button')}</LangLink>
            </div>
        </div>
    );
}

export default TherapistOverview;
