//Scripts
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import arrangements from './arrangements.js';
import therapies from './therapies.js';
import LangLink from './../translations/LangLink.js'
// import ThreeDimensionWheel from '../3Dwheel/3Dwheel.js';

//CSS
import './therapy-duration.css';
  
function TherapyAndDuration() {
    const { t } = useTranslation();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const therapyid = searchParams.get('therapyid');
    const therapy = therapies.find((therapy) => therapy.id === parseInt(therapyid, 10));

    // const itemToHtml = (arrangement) => (
    //     <div className='arrangement-container'>
    //         <LangLink key={arrangement.id} to={`/calendar?therapyid=${therapy.id}&arrangementid=${arrangement.id}`}>
    //             <p>{arrangement.durationInMinutes} min</p>
    //                 <img className='arrangement-img' src={arrangement.image} alt={arrangement.id}></img>
    //             <p>CHF {arrangement.priceInChf}</p>
    //         </LangLink>
    //     </div>
    // );

    return (
        <div className='therapy-container'>
            <h1>{t(therapy.labelKey)}</h1>
            {/* <p>{t("therapy-read-more")}</p> */}
            <br></br>
            {therapy.image ? (
                <img className="therapy-video" src={therapy.image} alt={t(therapy.labelKey)} />
            ) : (
                <video autoPlay loop muted>
                    <source className="therapy-video" src={therapy.videoPath} type={therapy.videoType} />
                </video>
            )}
            <div className='therapy-description'>
                <p>{t(therapy.labelKey + "-description")}</p>
            </div>

            <h1>{t("therapy-duration")}</h1>
            {/* <p>{t("therapy-duration-info")}</p> */}
            {/* <ThreeDimensionWheel itemKey='arrangement' width={60} figureWidth={80} items={arrangements} itemToHtml={itemToHtml} ></ThreeDimensionWheel> */}
            <div className='duration-container'>
                {arrangements.map((arrangement) => (
                    <LangLink key={arrangement.id} to={`/calendar?therapyid=${therapy.id}&arrangementid=${arrangement.id}`}>
                        <p className='booking-text'>{t('therapy-book-text')}</p>
                        <p>{arrangement.durationInMinutes} min</p>
                        <img src={arrangement.image} alt={arrangement.id}></img>
                        <p>CHF {arrangement.priceInChf}</p>
                    </LangLink>
                ))}
            </div>
        </div>
    );
}

export default TherapyAndDuration;
