//Scripts
import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import WeekPlan from './weekplan.js'
import TherapistPlan from './therapistPlan.js'

//CSS
import './operationPlan.css';

function OperationPlan({loginUser, logout}) {
    const { t } = useTranslation();
    const isFetchingRef = useRef(false);
    const [operationPlan, setOperationPlan] = useState(null);

    const loadOperationPlan = async () => {
        try {
            const bookingInfoUrl = `/api/therapist/operation-plan.php`;
            const response = await axios.get(bookingInfoUrl);
            setOperationPlan(response.data);
        } catch (error) {
            console.error('Error:', error);
            logout();
        }
    }

    const setWeekPlan = (response) => {
        if (response.data) {
            const newOperationPlan = operationPlan ? Object.assign({}, operationPlan) : {};
            newOperationPlan["weekplans"] = response.data;
            setOperationPlan(newOperationPlan);
        }
    }

    const thenSetOperationPlan = (response) => {
        if (response.data) {
            setOperationPlan(response.data);
        }
    }

    useEffect(() => {
        // Ensure to load only once
        if (isFetchingRef.current)
            return;
        else
            isFetchingRef.current = true;

        const intervalId = setInterval(() => {
            loadOperationPlan();
        }, 5 * 60 * 1000);
        loadOperationPlan();
        return () => {
            clearInterval(intervalId);
        };
    }, []);
    
    if (loginUser == null) {
        return <h2>{t("data-unauthenticated")}</h2>
    }

    if (operationPlan == null) {
        return <h2>{t("data-loading")}</h2>
    }

    if (operationPlan.length <= 0) {
        return <h2>{t("data-none")}</h2>
    }
    
    return (
        <div className='operation-plan'>
            <TherapistPlan logout={logout} operationPlan={operationPlan} thenSetOperationPlan={thenSetOperationPlan}></TherapistPlan>
            <WeekPlan logout={logout} weekplans={operationPlan.weekplans} setWeekPlan={setWeekPlan}></WeekPlan>
        </div>
    )
}

export default OperationPlan;
