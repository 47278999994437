export default function RotationRight({ className, color, width, height }) {
    const style = { fill: color };
    return (
        <svg className={className} width={width} height={height} viewBox="0 0 80 20">
            <defs
                id="defs1000" />
            <g
                id="layer1">
                <path
                style={style}
                d="M 39.193677,27.715062 V 25.430126 L 33.84118,25.186372 C 23.613865,24.720616 13.751367,23.477554 8.433526,21.984013 5.1719819,21.067987 1.6485813,19.159292 0.89365943,17.899509 0.35428005,16.99942 -0.29089872,11.54417 0.14202975,11.54417 c 0.14006044,0 1.01868735,0.443008 1.95250305,0.984463 4.2655011,2.473265 17.2876312,4.423833 31.9343502,4.783404 l 5.136236,0.12609 0.115269,-2.302061 0.11527,-2.302068 7.978298,4.268928 7.978294,4.26893 -8.079291,4.314076 L 39.193677,30 Z m 21.098475,-7.016994 0.109473,-4.232214 3.635658,-0.494991 c 7.207878,-0.981347 11.826536,-2.1736 14.334557,-3.700303 1.630505,-0.992527 1.778625,-0.739214 1.536025,2.626762 -0.178255,2.473051 -0.292229,2.784561 -1.497411,4.092993 -1.436814,1.559896 -3.387565,2.485776 -8.111757,3.850108 -2.099244,0.606249 -9.154172,2.089857 -9.937803,2.089857 -0.09813,0 -0.129039,-1.904496 -0.06882,-4.232212 z M 71.712619,11.627794 C 66.967953,9.6636678 54.905437,8.0559261 41.112497,7.5492834 L 37.577829,7.4194479 V 9.1005998 10.781752 L 32.730284,8.1976418 C 30.064136,6.7763802 27.882741,5.5133365 27.882741,5.3908762 c 0,-0.12246 2.181395,-1.385505 4.847543,-2.8067658 L 37.577829,0 v 1.6395864 1.6395858 l 5.756458,0.1515114 c 17.501374,0.4606406 28.235806,2.0546999 31.864272,4.7318328 1.628076,1.2012102 1.563618,2.1293876 -0.213843,3.0793866 -1.521851,0.813377 -2.08033,0.879242 -3.272097,0.385891 z M 4.4673948,10.72961 C 2.8647142,9.690426 2.8082841,8.6136149 4.3066311,7.6618947 6.3707973,6.3507772 14.204734,4.6680945 20.712415,4.1380296 l 1.514858,-0.1233885 v 2.0007552 2.0007549 l -3.328856,0.3823244 c -4.18388,0.4805257 -7.7256,1.2335779 -10.8054397,2.2974894 l -2.3992472,0.828804 z"
                id="path1080" />
            </g>
        </svg>
   );
}
