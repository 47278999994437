export function getBrowserLanguage(resources) {
    const browserLanguage = navigator.language || navigator.userLanguage;
    const availableLanguages = Object.keys(resources);
  
    // Check if the browser language or its prefix is in the available languages
    const language = availableLanguages.find(lang => browserLanguage.startsWith(lang));
  
    return language || 'de'; // default language
};

export function getUserDisplay(userObj) {
    var userDisplay = "";
    if (userObj["firstname"]) {
        userDisplay += userObj["firstname"];
    }

    if (userObj["lastname"]) {
        if (userDisplay) {
            userDisplay += " ";
        }
        userDisplay += userObj["lastname"];
    }
    if (!userDisplay) {
        userDisplay = userObj["email"];
    }

    return userDisplay == null ? "" : userDisplay;
};

export function getAquiredCustomerDisplay(userObj) {
    var userDisplay = "";
    if (userObj["au_firstname"]) {
        userDisplay += userObj["au_firstname"];
    }

    if (userObj["au_lastname"]) {
        if (userDisplay) {
            userDisplay += " ";
        }
        userDisplay += userObj["au_lastname"];
    }
    if (!userDisplay) {
        userDisplay = userObj["au_email"];
    }

    return userDisplay == null ? "" : userDisplay;
};

//TODO: a copy of stringFormat can also be find in calendarLogic.js. Replace the function there.
export function stringFormat(format, ...args) {
    return (format + "").replace(/{(\d+)}/g, (match, index) => {
        return typeof args[index] !== 'undefined' ? args[index] : match;
    });
}

export function userMandatoryPropsValid(user) {
    if (!user) return false;
    const propConfig = {
        "firstname": { mandatory: true, type: "text" },
        "lastname": { mandatory: true, type: "text" },
        "phone": { mandatory: true, type: "text" }
    }
    return Object.keys(propConfig).every(prop => {
        return specificValidate(prop, propConfig[prop], user[prop]);
    })
}

export function specificValidate(property, propInfo, inputValue) {
    if (!inputValue) return false;
    //console.log("property=" + property + " | propInfo=" + propInfo + " | inputValue=" + inputValue);

    // Perform input validation for text properties which are mandatory
    if (propInfo.type === "text" && propInfo.mandatory) {
        if (property === "phone") {
            const checkValue = inputValue.replace(/ /g, "").replace(/ /g, "");
            if (/^\+\d{2}/.test(checkValue)) {
                return checkValue.length === 12;
            } else if (/^00\d{2}/.test(checkValue)) {
                return checkValue.length === 13;
            } else {
                return checkValue.length >= 10;
            }
        } else {
            return inputValue.length >= 2;
        }
    }

    if (propInfo.type === "password") {
        return inputValue.length >= 8;
    }

    return true;
};

export function filterClients(clients, filter) {
    return clients.filter(client => {
        let skippedByFilter = filter !== "";
        Object.keys(client).forEach(property => {
            if (property === "id" || !client[property] || (property === "address" && client[property].length < 4)) {
                return;
            }

            var value = client[property];

            if (filter && value && String(value).toLowerCase().includes(filter.toLowerCase())) {
                skippedByFilter = false;
            }
        });

        return !skippedByFilter;
    });
};

export async function sha256(message) {
    // Encode the message as a Uint8Array (UTF-8)
    const msgBuffer = new TextEncoder().encode(message);
    
    // Hash the message using the SHA-256 algorithm
    const hashBuffer = await crypto.subtle.digest('SHA-256', msgBuffer);
    
    // Convert the ArrayBuffer to an array of bytes
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    
    // Convert the bytes to a hexadecimal string
    const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join('');
    
    return hashHex;
}

export function customerSorting(c1, c2, sortOrder, dateNow) {
    if (sortOrder === 'closest-bookings') {
        const date1 = new Date(c1["closest_booking_date"]);
        const date2 = new Date(c2["closest_booking_date"]);
        const diff1 = Math.abs(dateNow - date1);
        const diff2 = Math.abs(dateNow - date2);
        return diff1 - diff2;
    } else if (sortOrder === 'newest-registration') {
        return new Date(c1["creation_date"]) < new Date(c2["creation_date"])
    } else {
        console.log("Unknown sort order '" + sortOrder + "'! :-(");
    }
}
