// Libraries
import { useEffect, useRef, useState } from "react";
import { useTranslation } from 'react-i18next';
import { getUserDisplay } from '../../utils/businessUtils.js';
import LangLink from '../../translations/LangLink.js'

//CSS
import './voucherTransactions.css';

function VoucherDisplay({voucher}) {
    const { t } = useTranslation();

    return (
        <div>
            <h3>Transaktionen</h3>
            <p>Gutschein {voucher.code} selektiert, Käufer: {getUserDisplay(voucher)}</p>
            <p>Transaktionsliste noch nicht implementiert</p>
            {t('voucher-open')}
            <LangLink className={'underline'} target='_blank' to={'/api/pdf/voucher.php?language_code=de&code=' + voucher.code}>
                {t('language-de')}
            </LangLink> <LangLink className={'underline'} target='_blank' to={'/api/pdf/voucher.php?language_code=en&code=' + voucher.code}>
                {t('language-en')}
            </LangLink> <LangLink className={'underline'} target='_blank' to={'/api/pdf/voucher.php?language_code=ru&code=' + voucher.code}>
                {t('language-ru')}
            </LangLink>
        </div>
    );
}

export default VoucherDisplay;
