// Libraries
import { useEffect, useRef, useState } from "react";
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { t } from "i18next";
import voucherSujets from '../../vouchers/voucherSujets.json';
import voucherPayments from '../../vouchers/voucherPayments.json';
import { getUserDisplay } from './../../utils/businessUtils.js';
import VoucherDisplay from './voucherDisplay.js'
import { dateAndTime } from './../../utils/dateTimeUtils.js'

//CSS
import './voucherList.css';

function VoucherList({loginUser, logout}) {
    const { t } = useTranslation();
    const isFetchingRef = useRef(false);
    const [vouchers, setVouchers] = useState(null);
    const [selectedVoucher, setSelectedVoucher] = useState(null);

    const url = `/api/voucher.php?action=list`;
    const loadVoucherList = async () => {
        axios.get(url)
        .then((data) => {
            if (data.data.Status === "success") {
                setVouchers(data.data.vouchers);
            }

            isFetchingRef.current = false;
        })
        .catch(error => {
            console.error('Error:', error);
            logout();
        });
    };

    const findLabelById = (id, options) => {
        const option = options.find(opt => opt.id === id);
        return option ? t(option.label) : '';
    };

    const voucherEdit = (voucherId) => {
        setSelectedVoucher(vouchers.find(v => v.id === voucherId));
    }

    useEffect(() => {
        // Ensure to load only once
        if (isFetchingRef.current)
            return;
        else
            isFetchingRef.current = true;
            loadVoucherList();
    }, []);

    if (loginUser == null) {
        return <h2>{t("data-unauthenticated")}</h2>
    }

    if (vouchers == null) {
        return <h2>{t("data-loading")}</h2>
    }

    if (vouchers.length <= 0) {
        return <h2>{t("data-none")}</h2>
    }

    const rows = vouchers.map((voucher) => {
        const voucherClass = "voucher-manage-row" + (selectedVoucher && selectedVoucher.id === voucher.id ? " selected-voucher" : "");
        const creationDate = new Date(voucher.creation_date);
        return (
            <tr key={"voucher-" + voucher.id} className={voucherClass} onClick={() => voucherEdit(voucher.id)}>
                <td>{dateAndTime(creationDate)}</td>
                <td>{voucher.code}</td>
                <td>{getUserDisplay(voucher)}</td>
                <td>{findLabelById(voucher.payment, voucherPayments)}</td>
                <td>{voucher.voucherFrom}</td>
                <td>{voucher.voucherTo}</td>
                <td>{findLabelById(voucher.sujet, voucherSujets)}</td>
                <td>CHF {voucher.voucherAmount}</td>
                <td>CHF {voucher.voucherSaldo}</td>
            </tr>
        );
    })

    return (
        <div>
            <h3>{t('voucher-list')}</h3>
            <table className="voucher-table">
                <thead>
                    <tr>
                        <th>Anfrag Datum</th>
                        <th>Code</th>
                        <th>Käufer</th>
                        <th>Sujet</th>
                        <th>Von (Name)</th>
                        <th>Für (Name)</th>
                        <th>Bezahlung</th>
                        <th>Initial-Betrag</th>
                        <th>Aktueller-Saldo</th>
                    </tr>
                </thead>
                <tbody>{rows}</tbody>
            </table>
            {selectedVoucher ? (
                <VoucherDisplay voucher={selectedVoucher}></VoucherDisplay>
            ) : null}
        </div>
    );
}

export default VoucherList;
