//Scripts
import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import TwoRowCustomerFilter from './twoRowCustomerFilter.js'

//CSS
import './twoRowCustomerFilter.css';

function LoadTwoRowCustomerFilter({ loginUser, logout, allCustomerFilterAvailable, filterEmail, setFilterEmail, choicePerRow }) {
    const { t } = useTranslation();
    const isFetchingRef = useRef(false);
    const [customerList, setCustomerList] = useState(null);

    const loadClientList = async () => {
        try {
            const bookingInfoUrl = `/api/therapist/clientList.php`;
            const response = await axios.get(bookingInfoUrl);
            setCustomerList(response.data);
        } catch (error) {
            console.error('Error:', error);
            logout();
        }
    }

    useEffect(() => {
        // Ensure to load only once
        if (isFetchingRef.current)
            return;
        else
            isFetchingRef.current = true;

        loadClientList();
    }, []);

    if (loginUser == null) {
        return <h2>{t("data-unauthenticated")}</h2>
    }

    if (customerList == null) {
        return <h2>{t("data-loading")}</h2>
    }

    return (
        <TwoRowCustomerFilter 
            customers={customerList}
            allCustomerFilterAvailable={allCustomerFilterAvailable}
            filterEmail={filterEmail}
            setFilterEmail={setFilterEmail}
            choicePerRow={choicePerRow}>
        </TwoRowCustomerFilter>
    );
}

export default LoadTwoRowCustomerFilter;
