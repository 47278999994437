//Scripts
import React, { useState } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { toIsoDateWithoutTime, toDisplayDateWithoutTime, dateInPast, stringDateInPast, toMonday, addDays } from '../../utils/dateTimeUtils.js';

//CSS
import './therapistPlan.css';

function OperationPlan({logout, operationPlan, thenSetOperationPlan}) {
    const { t } = useTranslation();
    // const [showAddButton, setShowAddButton] = useState(true);
    const [formData, setFormData] = useState(
    {
        userId: operationPlan.therapists[0] ? operationPlan.therapists[0].id : 0,
        startDate: toIsoDateWithoutTime(toMonday(new Date(), 1)),
        sequence: 10,
        weekPlanId: operationPlan.weekplans[0] ? operationPlan.weekplans[0].id : 0
    });

    const calcShowAddButon = (data) => {
        let date = new Date(data.startDate)
        date = addDays(date, 7);
        if (dateInPast(date)) {
            return false;
        }

        return operationPlan.therapists.every(therapist =>
            therapist.id !== data.userId || therapist.therapistplans.every(tPlan =>
                toIsoDateWithoutTime(new Date(tPlan.start_date)) !== data.startDate || tPlan.weekplans.every(wPlan => wPlan.sequence != data.sequence)
            )
        );
    }

    const handlePropertyValueChange = (property, value) => {
        const clonedFormData = Object.assign({}, formData);
        if (property === "startDate") {
            value = toIsoDateWithoutTime(toMonday(new Date(value), -1));
        }

        clonedFormData[property] = value;
        clonedFormData["showAddButton"] = calcShowAddButon(clonedFormData);
        setFormData(clonedFormData);
    };

    const onFormChange = (e) => {
        e.target.focus(); //required together with onBlur to take effect
        const { name, value, nodeName } = e.target;
        if (nodeName === "SELECT") {
            const selectedOption = e.target.options[e.target.selectedIndex];
            const selectedOptionId = selectedOption.getAttribute("value");
            handlePropertyValueChange(name, selectedOptionId);
        } else {
            handlePropertyValueChange(name, value);
        }
    };

    const formSubmit = (e) => {
        e.preventDefault();
        formData["type"] = "add";
        axios.post('/api/therapist/therapist-plan.php', formData)
            .then(thenSetOperationPlan)
            .catch(error => {
                console.error('Error:', error);
                logout();
            });
    };

    const therapistPlanRemove = (e, sequence, therapistPlanId) => {
        e.preventDefault();
        const postData = {};
        postData["type"] = "remove";
        postData["therapistPlanId"] = therapistPlanId;
        postData["sequence"] = sequence;
        axios.post('/api/therapist/therapist-plan.php', postData)
            .then(thenSetOperationPlan)
            .catch(error => {
                console.error('Error:', error);
                logout();
            });

    };

    const tableHead = (
        <tr>
            <th>{t("therapist-plan-therapist")}</th>
            <th>{t("therapist-plan-begin")}</th>
            <th>{t("therapist-plan-sequence")}</th>
            <th>{t("therapist-plan-weekplan")}</th>
            <th></th>
        </tr>
    );

    const therapistOptions = operationPlan.therapists.map((therapist) => {
        var therapistDisplay = therapist.firstname;
        if (therapistDisplay && therapist.lastname) {
            therapistDisplay += " " + therapist.lastname;
        }

        return (
            <option key={"t-" + therapist.id} id={therapist.id} value={therapist.id}>{therapistDisplay}</option>
        )
    });
    const weekplanOptions = operationPlan.weekplans.map((weekplan) => {
        return (
            <option key={"w-" + weekplan.id} id={weekplan.id} value={weekplan.id}>{weekplan.name}</option>
        )
    });

    formData["showAddButton"] = calcShowAddButon(formData);
    const addPlanRow = () => {
        return (
        <tr key={"therapist-plan-add" + formData.showAddButton}>
            <th>
                <select id="userId" name="userId" onChange={onFormChange} >
                    {therapistOptions}
                </select>
            </th>
            <th>
                <input id="startDate" name="startDate" onChange={onFormChange} value={formData.startDate} type="date"></input>
            </th>
            <th>
                <input id="sequence" name="sequence" onChange={onFormChange} defaultValue={formData.sequence} type='number'></input>
            </th>
            <th>
                <select id="weekPlanId" onChange={onFormChange} name="weekPlanId">
                    {weekplanOptions}
                </select>
            </th>
            <th>
                {formData.showAddButton ? (<button>+</button>) : null}
            </th>
        </tr>
    )};

    var tPlanCount = 0;
    const tableRows = operationPlan.therapists.map((therapist) => {
        if (!therapist["therapistplans"] || therapist["therapistplans"].length <= 0) return null;
        var therapistDisplay = therapist.firstname;
        if (therapistDisplay && therapist.lastname) {
            therapistDisplay += " " + therapist.lastname
        }
        if (!therapistDisplay) {
            therapistDisplay = therapist.email;
        }

        return therapist.therapistplans.sort((pA, pB) => {
            return new Date(pB.start_date) < new Date(pA.start_date);
        }).map((tPlan) => {
            tPlanCount++;
            if (!tPlan["weekplans"] || tPlan["weekplans"].length <= 0) return null;
            const key2 = therapist.id + "-" + tPlan.id
            const displayDate = toDisplayDateWithoutTime(new Date(tPlan.start_date));
            const colSpanCells = (
                <React.Fragment key={key2}>
                    <td rowSpan={tPlan.weekplans.length}>
                            {therapistDisplay}
                    </td>
                    <td rowSpan={tPlan.weekplans.length}>
                        {displayDate}
                    </td>
                </React.Fragment>
            );
            return tPlan.weekplans.map((weekplan, index3) => {
                if (!weekplan || !weekplan["weekPlanId"]) return null;
                const key3 = therapist.id + "-" + tPlan.id + "-" + weekplan.id + "-" + weekplan.sequence;
                const weekplanName = operationPlan.weekplans.find(wp => wp.id === weekplan.weekPlanId).name;
                const hideRemoveButton = stringDateInPast(tPlan.start_date);
                return (
                    <tr key={"tr-" + key3} style={{ backgroundColor: (tPlanCount % 2 === 0 ? null : "#00760033") }}>
                        {index3 === 0 ? colSpanCells : null}
                        <td>
                            {weekplan.sequence}
                        </td>
                        <td>
                            {weekplanName}
                        </td>
                        <td>
                            {hideRemoveButton ? null : (<button onClick={(e) => therapistPlanRemove(e, weekplan.sequence, tPlan.id)}>-</button>) }
                        </td>
                    </tr>
                )
            });
        });
    });

    return (
        <React.Fragment>
            <h2>{t("time-therapist-plan-header")}</h2>
            <form onSubmit={formSubmit}>
                <table className='therapist-plan'>
                    <thead>
                        {tableHead}
                    </thead>
                    <tbody>
                        {addPlanRow()}
                        {tableRows}
                    </tbody>
                </table>
            </form>
        </React.Fragment>
    );
}

export default OperationPlan;