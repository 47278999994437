//Scripts
import React from 'react';
import { useTranslation } from 'react-i18next';

//CSS
import './aboutMe.css';

//Images
import albinaImg from './../img/albina.jpg';

function AboutMe() {
    const { t } = useTranslation();
    // Keep one div because the child contains display: flex. It appears like TransitionGroup can't handle it propertly / directly.
    return (
            <div className='about-me-container'>
                <img className='about-me-item portrait' src={albinaImg} alt="albina"></img>
                <div className='about-me-item'>
                    <h2>{t('therapists-my-story-header')}</h2>
                    {/* <p>{t('therapists-my-story-text1')}</p> */}
                    {/* <p>{t('therapists-my-story-text2')}</p> */}
                    <p className='about-me-text'>{t('therapists-my-story-text3')}</p>
                    <p className='about-me-text'>{t('therapists-my-story-text4')}</p>
                    {/* <p>{t('therapists-my-story-text5')}</p> */}
                </div>
            </div>
    );
}

export default AboutMe;
