//Scripts
import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { toIsoDateWithoutTime, toDisplayDateWithoutTime, decimalHourToTimeString } from './../../utils/dateTimeUtils.js';
import absenceTypes from './absenceTypes.json';
import { stringFormat } from './../../calendar/calendarLogic';
import { getUserDisplay } from '../../utils/businessUtils.js';

//CSS
import './absences.css';

function Absences({loginUser, logout}) {
    const { t } = useTranslation();
    const isFetchingRef = useRef(false);
    const [absenceData, setAbsenceData] = useState(null);
    const [upcommingAbsences, setUpcommingAbsences] = useState(true);
    const [noUserBookingForNextHours, setNoUserBookingForNextHours] = useState();
    const [formData, setFormData] = useState({
        date: toIsoDateWithoutTime(new Date()),
        typeId: 4,
        hour_from: 0,
        hour_to: 24,
    });
    const now = new Date();

    const updateNoUserBookingForNextHours = (e) => {
        e.target.focus(); //required together with onBlur to take effect
        setNoUserBookingForNextHours(e.target.value);
    };

    const onBlurNoUserBookingForNextHours = () => {
        axios.get('/api/therapist/db-settings.php?name=noUserBookingForNextHours&val=' + noUserBookingForNextHours)
        .catch(error => {
            console.error('Error:', error);
        });
    }

    const loadAbsences = async () => {
        try {
            //TODO: later forward info about upcomming or past absences to server... only load what's needed.
            //?upcommingAbsences=` + upcommingAbsences
            const bookingInfoUrl = '/api/therapist/absence-list.php';
            const response = await axios.get(bookingInfoUrl);
            setAbsenceData(response.data);
        } catch (error) {
            console.error('Error:', error);
            logout();
        }
    }

    useEffect(() => {
        // Ensure to load only once
        if (isFetchingRef.current)
            return;
        else
            isFetchingRef.current = true;
        
        const intervalId = setInterval(() => {
            loadAbsences();
        }, 5 * 60 * 1000);
        loadAbsences();
        return () => {
            clearInterval(intervalId);
        };
    }, []);
    
    if (loginUser == null) {
        return <h2>{t("data-unauthenticated")}</h2>
    }

    if (absenceData == null || !absenceData['generalAbsences']) {
        return <h2>{t("data-loading")}</h2>
    }

    if (absenceData.length <= 0) {
        return <h2>{t("data-none")}</h2>
    }

    const absenceRemove = async (e, absenceId) => {
        e.preventDefault();
        axios.post('/api/therapist/absence-remove.php', {absenceId})
        .then(() => {
            loadAbsences();
        })
        .catch(error => {
            console.error('Error:', error);
        });
    }

    const handlePropertyValueChange = (property, value) => {
        if (property === "hour_to" || property === "hour_from") {
            value = value * 1;
            if (value < 0) {
                value = 0;
            } else if (value > 24) {
                value = 24;
            }
        }

        setFormData({ ...formData, [property]: value });
    };
    const handleChange = (e) => {
        e.target.focus(); //required together with onBlur to take effect
        const { name, value, nodeName } = e.target;
        if (nodeName === "SELECT") {
            const selectedOption = e.target.options[e.target.selectedIndex];
            const selectedOptionId = selectedOption.getAttribute("value");
            handlePropertyValueChange(name, selectedOptionId);
        } else {
            handlePropertyValueChange(name, value);
        }
    };

    const absenceAdd = async (e) => {
        e.preventDefault();
        axios.post('/api/therapist/absence-add.php', formData)
        .then(() => {
            loadAbsences();
        })
        .catch(error => {
            console.error('Error:', error);
        });
    }

    const rows = absenceData.generalAbsences
        .filter(absence => upcommingAbsences && now < new Date(absence["date"]) || !upcommingAbsences && now > new Date(absence["date"]))
        .sort((absA, absB) => upcommingAbsences && new Date(absA["date"]) > new Date(absB["date"]) || 
                             !upcommingAbsences && new Date(absA["date"]) < new Date(absB["date"]))
        .map((absence) => {
        const absenceType = absenceTypes.find((type) => type.id === absence["booking_type"]);
        const userDisplay = getUserDisplay(absence);
        var absenceTypeDisplay = t("absence-" + absenceType.label);
        if (userDisplay) {
            absenceTypeDisplay += " " + userDisplay;
        }

        const timeFrame = absence["hour_from"] * 1 <= 0 && absence["hour_to"] * 1 >= 24 ? t("absence-full-day") : (
            stringFormat(t("booking-time-range"), decimalHourToTimeString(absence["hour_from"]), decimalHourToTimeString(absence["hour_to"]))
        )
        const date = new Date(absence["date"]);
        const dateDisplay = toDisplayDateWithoutTime(date);

        return (
            <tr key={absence["id"]}>
                <td><button onClick={(e) => absenceRemove(e, absence["id"])}>-</button></td>
                <td>{dateDisplay}</td>
                <td>{absenceTypeDisplay}</td>
                <td>{timeFrame}</td>
            </tr>
        )
    });

    const absenceTypesOptions = absenceTypes.filter(item => item.id !== 1).map((aType) => {
        return ( <option key={"absence-" + aType.id} id={aType.id} value={aType.id}>{t("absence-" + aType.label)}</option> )
    });

    return (
        <div className='absence-container'>
            <h2>{t('absence-shortterm-headder')}</h2>
            <div>
                {t('absence-shortterm-description')}
                <br></br>
                <input className='absence-shortterm' type='number' step='0.5' 
                    defaultValue={absenceData.noUserBookingForNextHours}
                    onChange={updateNoUserBookingForNextHours} 
                    onBlur={onBlurNoUserBookingForNextHours} 
                    ></input> {t('time-hours')}
            </div>
            <h2>{t('absence-general-header')}</h2>
            <div className='absence-tabs'>
                <div 
                    className={'absence-tab' + (upcommingAbsences ? ' active' : '')}
                    onClick={() => setUpcommingAbsences(true)}>
                    {t('absences-tab-upcomming')}
                </div>
                <div
                    className={'absence-tab' + (upcommingAbsences ? '' : ' active')}
                    onClick={() => setUpcommingAbsences(false)}>
                    {t('absences-tab-past')}
                </div>
            </div>
            <form className='absence-div' onSubmit={absenceAdd}>
                <table className='absence-table'>
                    <thead>
                        <tr>
                            <th>{t("edit-column")}</th>
                            <th>{t("date")}</th>
                            <th>{t("absence-type")}</th>
                            <th>{t("absence-time-frame")}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td><button type="submit">+</button></td>
                            <td><input id='date' name='date' type='date' value={formData.date} onChange={handleChange}></input></td>
                            <td>
                                <select id="typeId" name="typeId" onChange={handleChange}>
                                    {absenceTypesOptions}
                                </select>
                            </td>
                            <td>
                                <span className='absence-time-range'>{t("absence-from-hour")} </span><input id='hour_from' name='hour_from' type="number" value={formData.hour_from} onChange={handleChange}></input>
                                <br></br>
                                <span className='absence-time-range'>{t("absence-to-hour")} </span><input id='hour_to' name='hour_to' type="number" value={formData.hour_to} onChange={handleChange}></input>
                            </td>
                        </tr>
                        {rows}
                    </tbody>
                </table>
            </form>
        </div>
    );
}

export default Absences;
