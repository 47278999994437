//Scripts
import React, { useState } from 'react';
import SpellCheckTextareaAutosize from './utils/SpellCheckTextareaAutosize.js';

//CSS
import './genericInput.css';

function GenericInput({ property, propInfo, value, axiosPostCall, updateDataObject, specificValidate }) {
    const [inputContext, setInputContext] = useState({});
    const [passwordRepeat, setPasswordRepeat ]= useState("");

    const setInputContextCommon = (value, type) => {
        //console.log("property=" + property + " | value=" + value + " | type=" + type);
        setInputContext(prevStatus => {
            const newStatus = { ...prevStatus };
            newStatus[type] = value;
            return newStatus;
        });
    };

    // console.log("property=" + property + " | value=" + value)

    const setInputValidation = (value) => {
        setInputContextCommon(value, "valid");
    }

    const setInputStatus = (value) => {
        setInputContextCommon(value, "status");
    }

    const onChangeEvent = (event) => {
        const value = event.target.value;
        // console.log("onChangeEvent | value=" + value);

        if (propInfo.type === "password") {
            setPasswordRepeat(prevStatus => {
                const newStatus = { ...prevStatus };
                newStatus[event.target.name] = value;
                return newStatus;
            });
        }

        if (specificValidate) {
            var valid = specificValidate(property, propInfo, value)
            if (valid && propInfo.type === "password") {
                const firstInputValue = event.target.name === "password_repeat" ?
                    passwordRepeat["password"] :
                    passwordRepeat["password_repeat"];
                valid = value === firstInputValue;
            }

            setInputValidation(valid);
        }

        setInputStatus("generic-input-open-changes");
        updateDataObject(property, value);
    }

    const onBlurEvent = (event) => {
        var value = event.target.value;
        // console.log("onBlurEvent | value=" + value);
        
        // Only continue if there are open changes
        if (inputContext["status"] !== "generic-input-open-changes") return;
    
        // Exit if invalid... and don't update
        if (inputContext["valid"] === false) {
            return;
        }

        // Set status to processing
        setInputStatus("generic-input-processing");
        
        // Send a POST request to updateUser.php to update the property in the database
        axiosPostCall(property, value)
        .then(() => {
            setInputStatus("generic-input-updated");
        })
        .catch(error => {
            console.error('Error:', error);
        });
    }

    if (propInfo.type === "list") {
        return (
            <select 
                    key={"select-" + property}
                    className="generic-input"
                    onChange={onChangeEvent}
                    onBlur={onBlurEvent}
                    value={value || ''}>
                {Object.entries(propInfo.list)
                        .sort(([, optionValueA], [, optionValueB]) => optionValueA.localeCompare(optionValueB))
                        .map(([key, optionValue]) => (
                    <option key={"O-" + property + "-" + key} value={key}>
                        {optionValue}
                    </option>
                ))}
            </select>
        );
    }
    
    //Set html class specific if invalid
    var inputClassName = "generic-input";
    if (inputContext.hasOwnProperty("valid") &&
        !inputContext.valid) {
        inputClassName += " generic-input-invalid";
    }

    if (inputContext["status"]) {
        inputClassName += " " + inputContext.status;
    }

    if (propInfo.type === "date") {
        value = value == null ? "" : value.slice(0, 10);
    }

    if (propInfo.type === "textarea") {
        return (
            <SpellCheckTextareaAutosize 
                key={"GI-" + property}
                className={inputClassName}
                name={property}
                value={value || ''}
                onChange={onChangeEvent}
                onBlur={onBlurEvent}
                rows="1" />
        );
    }

    if (propInfo.type === "password") {
        return (
            <React.Fragment>
                <input
                    key={"GI-" + property}
                    className={inputClassName}
                    name={property}
                    type={propInfo.type}
                    value={passwordRepeat[property] || ''}
                    onChange={onChangeEvent}
                    onBlur={onBlurEvent} />
                <br></br>
                <input
                    key={"GI-" + property + "_repeat"}
                    className={inputClassName}
                    name={property + "_repeat"}
                    type={propInfo.type}
                    value={passwordRepeat[property + "_repeat"] || ''}
                    onChange={onChangeEvent}
                    onBlur={onBlurEvent} />
            </React.Fragment>
        );
    }

    return (
        <input
            key={"GI-" + property}
            className={inputClassName}
            name={property}
            type={propInfo.type}
            value={value || ''}
            onChange={onChangeEvent}
            onBlur={onBlurEvent} />
    );
}

export default GenericInput;
