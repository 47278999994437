//Scripts
import React from 'react';
import { decimalHourToTimeString } from './../../utils/dateTimeUtils.js';

//CSS
import './day-time-plan-graphe.css';

function DayTimePlanGraphe({ planDayTimes }) {
    if (planDayTimes == null || planDayTimes.lengh === 0) return;

    // Initialize variables to hold min_hour_from and max_hour_to
    let min_hour_from = Infinity;
    let max_hour_to = -Infinity;

    // Iterate over each planDayTime to find min_hour_from and max_hour_to
    planDayTimes.forEach(planDayTime => {
        min_hour_from = Math.min(min_hour_from, planDayTime.hour_from);
        max_hour_to = Math.max(max_hour_to, planDayTime.hour_to) - 0.5;
    });

    // Initialize an array to hold the JSX elements
    const timeUnits = [];

    // Iterate over each hour from min_hour_from to max_hour_to
    for (let hour = min_hour_from; hour <= max_hour_to; hour += 0.5) {
        // Check if the current hour falls within any planDayTime
        const isWorkingTime = planDayTimes.some(planDayTime => hour >= planDayTime.hour_from && hour < planDayTime.hour_to);

        // Check if the current hour is a flex break
        const isFlexBreak = planDayTimes.some(planDayTime =>
            planDayTime.flexBreaks.some(flexBreak => hour >= flexBreak.flex_break_start && hour < flexBreak.flex_break_start + flexBreak.preak_duration + 0.5)
        );

        // Determine if the current hour is a fix break
        const isFixBreak = !isWorkingTime ||
            planDayTimes.some(planDayTime =>
                planDayTime.flexBreaks.some(flexBreak => hour >= flexBreak.flex_break_start + 0.5 && hour < flexBreak.flex_break_start + flexBreak.preak_duration)
            );

        // Add the appropriate class name based on the type of time unit
        let className = '';
        if (isFixBreak) {
            className = 'fix-break';
        } else if (isFlexBreak) {
            className = 'flex-break';
        } else if (isWorkingTime) {
            className = 'working-time';
        }

        // Create a <div> element for the current time unit and push it to the 'timeUnits' array
        timeUnits.push(
            <div key={hour} className={`time-unit ${className}`}>
                <p>{decimalHourToTimeString(hour)}</p>
            </div>);
    }

    // Render the time units
    return (
        <div className='time-unit-container'>
            <div>
                {timeUnits}
            </div>
        </div>
    );
}


export default DayTimePlanGraphe;
