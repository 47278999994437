//Scripts
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import calendarInfo from './../calendar/calendar-info';
import therapies from './../therapy/therapies.js';
import { stringFormat } from './../calendar/calendarLogic';
import arrangements from '../therapy/arrangements.js';
import TherapyTable from './therapyTable.js';
import { toIsoDateWithoutTime, dateToTimeDecimal } from './../utils/dateTimeUtils.js';
import SpellCheckTextareaAutosize from './../utils/SpellCheckTextareaAutosize.js';
import BookingByTherapist from './../therapist/bookingByTherapist.js';
import { userMandatoryPropsValid } from './../utils/businessUtils.js';
import { IsProd } from './../utils/environment.js';
import { sha256 } from '../utils/businessUtils';

//CSS
import './bookView.css';
import Account from '../login/account.js';

function BookView({loginUser, logout, updateLoginUser }) {
    const [minimalPersonalData, setMinimalPersonalData] = useState(null);
    const [bookingStatus, setBookingStatus] = useState("verification");
    const [bookingComment, setBookingComment] = useState();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const [bookingDateTime, setBookingDateTime] = useState(new Date(searchParams.get('date')));
    const { t } = useTranslation();
    const bookingInFuture = bookingDateTime > new Date();
    const [skipNotification, setSkipNotification] = useState(bookingInFuture ? false : true);

    const therapyid = searchParams.get('therapyid');
    const therapy = therapies.find((therapy) => therapy.id === parseInt(therapyid, 10));
    const { maxHoursCancelBeforeStart } = calendarInfo;
    const arrangementid = searchParams.get('arrangementid');
    const arrangement = arrangements.find((arrangement) => arrangement.id === parseInt(arrangementid, 10));

    const bookAppointment = async (email) => {
        const dateOnly = toIsoDateWithoutTime(bookingDateTime);
        const fromHour = dateToTimeDecimal(bookingDateTime);
        const response = await fetch('/api/booking/book.php',
        {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'User-Agent': navigator.userAgent,
            },
            body: JSON.stringify({ therapyid, arrangementid, dateOnly, fromHour, bookingComment, email, skipNotification })
        });
        
        const data = await response.json();
        setBookingStatus(data.bookingStatus);

        if (data.bookingStatus === 'success' && IsProd()) {
            const emailHash = await sha256(email.toLowerCase().trim());

            // Trigger Google Ads Conversion Tracking
            window.gtag('event', 'conversion', {
                'send_to': 'AW-16578861916/lPpvCPjiw8cZENy2teE9',
                'value': arrangement.priceInChf, // Optional: Set a value for the conversion
                'currency': 'CHF', // Adjust currency if needed
                'user_data': {
                    'email_address': emailHash // Gehashte E-Mail-Adresse
                },
                'event_callback': 'callback'
            });
        }
    };

    const therapieTableData = {
        therapy,
        bookingDate: bookingDateTime,
        arrangement
    };
    const onBookingDateTimeChange = (e) => {
        console.log(e.target.value);
        setBookingDateTime(new Date(e.target.value));
    }
    const dateTimeSetGet = loginUser && loginUser["is_therapist"] ? [bookingDateTime, onBookingDateTimeChange] : null;
    const therapieContext = (
        <div className='booking-inputs'>
            <h1>{t("booking-header")}</h1>
            <TherapyTable therapieTableData={therapieTableData} dateTimeSetGet={dateTimeSetGet}></TherapyTable>
            <ul>
                <li>{t('booking-conditions-feedback')}</li>
                <li>{stringFormat(t("booking-conditions-canculation"), maxHoursCancelBeforeStart)}</li>
                <li>{t("booking-conditions-our-canculation")}</li>
            </ul>
        </div>
    )

    const onCommentChangeEvent = (e) => {
        setBookingComment(e.target.value);
    };
    
    let bookingInfo = null;
    if (bookingStatus === "verification" && !loginUser)
    {
        bookingInfo = (
            <div>
                <h2>{t("booking-authenticate-first-header")}</h2>
                <p>{t("booking-authenticate-first-content")}</p>
            </div>
        )
    } else if (bookingStatus === "verification" && (!userMandatoryPropsValid(loginUser))) {
        if (!minimalPersonalData) {
            setMinimalPersonalData(<Account loginUser={loginUser} logout={logout} minimal={true} updateLoginUser={updateLoginUser}></Account>);
        }
    } else if (bookingStatus === "verification") {
        bookingInfo = (
            <div className='booking-feedback'>
                <br></br>
                <p>{t("booking-your-comments")}</p>
                <SpellCheckTextareaAutosize 
                    className="booking-comment-textarea"
                    name="booking-comment-textarea"
                    key="booking-comment-textarea"
                    onChange={onCommentChangeEvent}
                    maxLength={600} />
                <br></br>
                <br></br>
                <button onClick={() => bookAppointment(null)}>
                    <h3>{t('booking-possible')}</h3>
                </button>
            </div>
        )
    }
    else if (bookingStatus === "success")
    {
        bookingInfo =
        <div className='booking-feedback'>
            <h2>{t("booking-success-header")}</h2>
            <p>{t("booking-success-content")}</p>
        </div>
    }
    // else if (bookingStatus === "success-temp-user")
    // {
    //     bookingInfo =
    //     <div className='booking-feedback'>
    //         <h2>{t("booking-temp-success-header")}</h2>
    //         <p>{t("booking-temp-success-content")}</p>
    //     </div>
    // }
    else if (bookingStatus === "booking-failed")
    {
        bookingInfo =
        <div className='booking-feedback'>
            <h2>{t("booking-failed-header")}</h2>
            <p>{t("booking-failed-content")}</p>
        </div>
    }
    else if (bookingStatus === "not-authenticated")
    {
        if (loginUser) {
            logout();
        }

        bookingInfo =
        <div className='booking-feedback'>
            <h2>{t("booking-not-authenticated-header")}</h2>
            <p>{t("booking-not-authenticated-content")}</p>
        </div>
    }
    else
    {
        bookingInfo =
        <div className='booking-feedback'>
            <h2>{t("booking-in-progress-header")}</h2>
            <p>{t("booking-in-progress-content")}</p>
        </div>
    }
    return (
        <div className='booking-base'>
            {therapieContext}
            {minimalPersonalData}
            {bookingInfo}
            {loginUser && loginUser["is_therapist"] && bookingStatus !== "success" ? 
                <BookingByTherapist 
                    logout={logout} 
                    bookAppointment={bookAppointment} 
                    skipNotification={skipNotification} 
                    setSkipNotification={setSkipNotification}
                    bookingInFuture={bookingInFuture}>
                </BookingByTherapist> : null}
        </div>
    );
}

export default BookView;
