import React from 'react';
import './filterHeighlitedValue.css';

const FilterHeighlitedValue = ({ value, filter }) => {
    // Ensure value is treated as a string
    const stringValue = String(value);

    if (!value) {
        return null;
    }

    if (!filter) {
        return <span>{stringValue}</span>;
    }

    const regex = new RegExp(`(${filter})`, 'gi');
    const parts = stringValue.split(regex);

    return (
        <span>
            {parts.map((part, index) =>
                part.toLowerCase() === filter.toLowerCase() ? (
                    <span key={index} className="filter-highlighted">
                        {part}
                    </span>
                ) : (
                    part
                )
            )}
        </span>
    );
};

export default FilterHeighlitedValue;
