//Scripts
import React, { useState } from 'react';
import axios from 'axios';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import { toDisplayDateTime } from '../../utils/dateTimeUtils.js';
import arrangements from '../../therapy/arrangements.js';
import therapies from '../../therapy/therapies.js';
import SpellCheckTextareaAutosize from '../../utils/SpellCheckTextareaAutosize.js';
import TherapyTable from '../../booking/therapyTable.js';

//CSS
import './cancel-dialogue.css';
import { stringFormat } from '../../calendar/calendarLogic.js';

function CancelDialogue({booking, logout, loadBookingList}) {
    const { t } = useTranslation();
    const [emailSendStatus, setEmailSendStatus] = useState();
    const [textGeneratorInput, setTextGeneratorInput] = useState({
        "canceling-group-language": booking["language_code"],
        "canceling-group-reasoning": "canceling-due-to-general",
        "canceling-group-costs": "canceling-no-costs",
    });
    const bookingDate = new Date(booking["date"]);
    const therapy = therapies.find((therapy) => therapy.id === booking["therapy_id"]);
    const arrangement = arrangements.find((arrangement) => arrangement.id === booking["arrangement_id"]);
    const therapieTableData = {
        therapy,
        bookingDate,
        arrangement,
    };

    const onChange = (e) => {
        setTextGeneratorInput(prevState => {
            prevState = {};
            prevState["canceling-group-language"] = textGeneratorInput["canceling-group-language"];
            prevState["canceling-group-reasoning"] = textGeneratorInput["canceling-group-reasoning"];
            prevState["canceling-group-costs"] = textGeneratorInput["canceling-group-costs"];
            prevState[e.target.name] = e.target.value
            return prevState;
        });
    };

    const overwriteOnChange = (e) => {
        setTextGeneratorInput(prevState => ({
            ...prevState,
            "overwritten-text": e.target.value
        }));
    };

    const getGreetings = (booking, languageCode) => {
        const translationSetting = { "lng": languageCode };
        return booking["firstname"] && booking["lastname"] ? 
        stringFormat(t("email-welcome-again", translationSetting), booking["firstname"], booking["lastname"]) :
        stringFormat(t("email-welcome-again", translationSetting), "", "");
    }

    const getEmailMessage = (languageCode) => {
        const translationSetting = { "lng": languageCode };
        return t(textGeneratorInput["canceling-group-reasoning"] + "-email-text", translationSetting) + " " +
            t(textGeneratorInput["canceling-group-costs"] + "-email-text", translationSetting);
    };

    const sendEmail = (bookingId, emailMessageInCustomerLanguage) => {
        axios.post('/api/therapist/cancel-email.php', {
            bookingId, 
            individualText: emailMessageInCustomerLanguage,
            lang: textGeneratorInput["canceling-group-language"]
        })
        .then((response) => {
            if (response.data) {
                setEmailSendStatus(response.data);
            }
        })
        .catch(error => {
            console.error('Error:', error);
        });
    };

    const cancelBooking = async (id, revert, cancelTypeId) => {
        const response = await fetch('/api/booking/cancel.php',
        {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'User-Agent': navigator.userAgent,
            },
            body: JSON.stringify({ id, revert, cancelTypeId })
        });
        
        const data = await response.json();
        if (data["success"]) {
            loadBookingList();
        } else {
            console.log(data["error"]);
            logout();
        }
    };

    const revertCanculation = booking["cancel_date"] ? 
        (
            <React.Fragment>
                <button className='booking-list-link' onClick={() => cancelBooking(booking.id, true, null)}>{t("booking-prop-reactivate_booking")}</button>
                <br></br>
            </React.Fragment>
        ) : 
        null

    const textGeneratorSource = [
        {
            "group": "canceling-group-language",
            "list": [ "de", "en", "ru"],
            "labelPrefix": "language-"
        },
        {
            "group": "canceling-group-reasoning",
            "list": [ "canceling-due-to-general", "canceling-due-to-illness", "canceling-due-to-customer-request"]
        },
        {
            "group": "canceling-group-costs",
            "list": [ "canceling-no-costs", "canceling-partial-waiver", "canceling-full-compensation"]
        }
    ].map((source) => {
        const labelPrefix = source["labelPrefix"] ? source["labelPrefix"] : "";
        const listInputs = source.list.map((item) => {
            const isChecked = textGeneratorInput[source.group] === item;
            return (
                <span key={"radio-" + item}>
                    <input onChange={onChange} type="radio" id={item} name={source.group} value={item} checked={isChecked} />
                    <label htmlFor={item}>{t(labelPrefix + item)}</label>
                </span>
            );
        });
        return (
            <fieldset key={source.group} className='canceling-generator-input'>
                <legend>{t(source.group)}</legend>
                {listInputs}
            </fieldset>
        );
    });

    const emailMessageInUiLanguage = i18next.language !== textGeneratorInput["canceling-group-language"] && !textGeneratorInput["overwritten-text"] ? (
        <React.Fragment>
            <fieldset>
                <legend>{t("canceling-message-translation")}</legend>
                {<p>{getGreetings(booking, i18next.language)}</p>}
                <br></br>
                {getEmailMessage(i18next.language).split('\n').map(item => {
                    if (item) {
                        return <p>{item}</p>
                    } else {
                        return <br></br>
                    }
                })}
            </fieldset>
            <br></br>
        </React.Fragment>
    ) : null;
    const emailMessageInCustomerLanguage = textGeneratorInput["overwritten-text"] ? 
        textGeneratorInput["overwritten-text"] : 
        getEmailMessage(textGeneratorInput["canceling-group-language"]);
    const lang = { "lng": textGeneratorInput["canceling-group-language"] };
    const emailSignatureHTML = { __html: t("email-signature", lang) };
    const newAppointmentUrl = "/calendar?therapyid=" + booking["therapy_id"] + "&arrangementid=" + booking["arrangement_id"];

    const emailSendStatusText = emailSendStatus ? (
        emailSendStatus.success ? <span>{t("email-send-success")}</span> : <span>{t("email-send-failed")}</span>
    ) : null;

    return (
        <div className='cancel-dialogue'>
            <h4>{t('canceling-in-system')}</h4>
            <button className='booking-list-link'onClick={() => cancelBooking(booking.id, false, 3)}>{t('canceling-by-therapist')}</button>
            <br></br>
            <br></br>
            <div>
                <span style={{"paddingRight": "20px"}}>{t('canceling-by-customer')}</span>
                <button className='booking-list-link' onClick={() => cancelBooking(booking.id, false, 4)}>{t("canceling-by-customer-free")}</button>
                <button className='booking-list-link' onClick={() => cancelBooking(booking.id, false, 5)}>{t("canceling-by-customer-charged")}</button>
            </div>
            {revertCanculation}
            <br></br>
            <h4>{t('canceling-notification')}</h4>
            {textGeneratorSource}
            <br></br>
            {<p>{t("email-to") + ": " + booking["email"]}</p>}
            {<p>{t("email-subject") + ": " + stringFormat(t('canceling-email-subject', lang), toDisplayDateTime(bookingDate))}</p>}
            <br></br>
            {emailMessageInUiLanguage}
            {<p>{getGreetings(booking, textGeneratorInput["canceling-group-language"])}</p>}
            <fieldset>
                <legend>{t('canceling-email-text')}</legend>
                <SpellCheckTextareaAutosize className='canceling-text-area' onChange={overwriteOnChange} value={emailMessageInCustomerLanguage} />
            </fieldset>
            <TherapyTable therapieTableData={therapieTableData} language={textGeneratorInput["canceling-group-language"]}></TherapyTable>
            <div><a href={newAppointmentUrl}>{t('canceling-to-new-appointment1', lang)}</a> {t('canceling-to-new-appointment2', lang)}</div>
            <br></br>
            <div dangerouslySetInnerHTML={emailSignatureHTML} />
            <br></br>
            <button onClick={() => sendEmail(booking.id, emailMessageInCustomerLanguage)} className='booking-list-link'>{t('canceling-send-mail')}</button>
            {emailSendStatusText}
        </div>
    );
}

export default CancelDialogue;
