//Scripts
import { useTranslation } from 'react-i18next';

//Images
import locationImg from './../img/location.jpg';

function Location() {
    const { t } = useTranslation();
      
    return (
        <div>
            <h2>{t('location-header')}</h2>
            <p>{t('header-title')}</p>
            <p>{t('location-street-no')}</p>
            {t('location-zip-city')}
            <br></br>
            <br></br>

            <a className='underline' href='https://www.google.com/search?q=google+maps+from%3A+my+location+to%3A+8152+Glattbrugg+Europa-Strasse+3\'>{t('about-us-google-navi')}</a>
            <br></br>

            {/* <div className="google-map">
            <iframe
                title="Praxis Location"
                width="600"
                height="450"
                frameBorder="0"
                style={{ border: 0 }}
                src={`https://www.google.com/maps/embed/v1/place?q=Europa-Strasse%203,%815202%20Glattbrugg`}
                allowFullScreen
            ></iframe>
            </div> */}
            <a className="google-map" href='https://www.google.com/maps/place/Europa-Strasse+3,+8152+Glattbrugg' target="_blank" rel="noreferrer">
                <img src={locationImg}></img>
            </a>
            
            <h2>{t('openinghours-header')}</h2>
            <p>{t('openinghours-content')}</p>

            <h2>{t('impressum-header')}</h2>
            {t('location-street-no')}<br />
            {t('location-zip-city')}<br /><br />
            {t('about-us-phone')}<br />
            {t('profile-email')}: <a className='underline' href="mailto:info@anatomicus.ch">info@anatomicus.ch</a><br /><br />
            {t('legal-form')}<br />
            {t('commercial-register-prop')} <a className='underline' href="https://zh.chregister.ch/cr-portal/auszug/auszug.xhtml?uid=CHE-303.755.510">{t('commercial-register-value')}</a><br />
            {t('authorized-to-sign')} Albina Schiltknecht
        </div>
    )
}

export default Location;
