export function moveKeyAfter(obj, keyToMove, keyAfter) {
    const newObj = {};
    Object.keys(obj).forEach(key => {
        if (key !== keyToMove) {
            newObj[key] = obj[key];
        }
        if (key === keyAfter) {
            newObj[keyToMove] = obj[keyToMove];
        }
    });
    return newObj;
}
